import { getApiUrl } from "../util";

// Try to login to the backend...
export async function backendLogin({userInitiated, idToken, onLoginStarted, onLoginSuccess, onLoginFailed, onIsLoggedOn}) {
    let step;
    try {
        // Tell the world that we are logging in...
        if (userInitiated) {
            // Inform our caller...
            onLoginStarted();
        }

        // Get the id_token...
        if (!idToken) {
            throw new Error('Unable to retrieve ID TOKEN');
        }

        // Try to ask the backend to log us in...
        const url = getApiUrl('/login');
        step = `Invoking ${url}`;
        const response = await fetch(url, {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({idToken}),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        });

        // Check the response...
        if (!response.ok) {
            throw new Error(`HTTP call failed: ${response.status}`);
        }

        // Get the email address from the server side
        step = `Reading the ${url} response`;
        const bodyText = await response.text(),
            basicProfile = JSON.parse(bodyText);

        // Convey that we are logged in
        onLoginSuccess(basicProfile);
    } catch (err) {
        // Failed to initialize!
        console.log(`Failed during ${step}: ${err}`);

        // Inform our caller
        if (userInitiated) {
            onLoginFailed(err.message);
        } else {
            onIsLoggedOn(false);
        }
    }
}

// Log the user out of the backend
export async function backendLogout({userInitiated, onLogoutStarted, onLogoutSuccess, onLogoutFailed, onIsLoggedOn}) {
    let step;
    try {
        // Tell the world that we are logging in...
        step = 'Initiating onLogoutStarted';
        if (userInitiated && onLogoutStarted) {
            // Inform our caller...
            onLogoutStarted();
        }

        // Ask the backend to log us out...
        const url = getApiUrl('/session');
        step = `Invoking ${url}`;
        const response = await fetch(url, {
            credentials: 'include',
            method: 'DELETE',
        });

        // Check the response...
        if (!response.ok) {
            throw new Error(`HTTP call failed: ${response.status}`);
        }

        // Convey that we are logged in
        onLogoutSuccess && onLogoutSuccess();
    } catch (err) {
        // Failed to initialize!
        console.log(`Failed during ${step}: ${err}`);

        // Inform our caller
        if (userInitiated && onLogoutFailed) {
            onLogoutFailed(err.message);
        } else if (onIsLoggedOn) {
            onIsLoggedOn(false);
        }
    }
}
