import React from 'react';
import PropTypes from 'prop-types';

// Internal modules
import {replaceNewlineWithBreak} from './util';
import { F_BARS_LEAD_IN } from './fields';

// Our function component
function ReadOnlyChartSection(props) {

    // Generate the HTML for each of the TDs in the CHART TABLE. This is an ARRAY of ARRAYS of TDs
    const tdArray = props.chartInfoArray.map((row, rowIndex) => props.chartSections.map(cs => props.chartInfoArray[rowIndex].map((ci, ix) =>
        <td key={[rowIndex, ix].join('::')} className={'normal ' + cs.field}>
            <div dangerouslySetInnerHTML={{__html: replaceNewlineWithBreak(ci[cs.field])}}></div>
        </td>
    )));

    // Generate an ARRAY (1..chartInfoArray.length) of ARRAY (1..SECTIONS_IN_ROW.length) of ARRAY (1..2) with START_MEASURE, END_MEASURE
    const barNumArray = props.chartInfoArray.reduce((outerHash, rowArray, rowIndex) => {
        // Reduce the individual row into into a 2 DIMENSIONAL ARRAY
        const innerHash = props.chartInfoArray[rowIndex].reduce((res, obj) => {
            const nBars = obj.bars || 1;

            // Add to the array...
            res.arr.push([res.start, res.start+nBars-1]);

            // Increment the counter...
            res.start = res.start + nBars;

            return res;
        }, {start: outerHash.start, arr: []});

        // Append the inner hash's array to the outer one
        outerHash.arr.push(innerHash.arr);

        // Update the outer hash start index...
        outerHash.start = innerHash.start;

        return outerHash;
    }, {start: 1 + (props?.[F_BARS_LEAD_IN] || 0), arr: []}).arr;

    // Generate the HTML for each of the TDs in the MEASURE ROW of the CHART TABLE. This is an ARRAY of ARRAYS of TDs
    const tdBarNumHtml = props.chartInfoArray.map((row, rowIndex) =>
        props.chartInfoArray[rowIndex].map((ci, ix) =>
            <td key={[rowIndex, ix].join('::')} className='normal measure'>
                { ((ci.bars > 1 &&
                <>
                    <span className="bar-num-start">{barNumArray[rowIndex][ix][0]}</span>
                    <span className="bar-num-end">{barNumArray[rowIndex][ix][1]}</span>
                </>
                ) || barNumArray[rowIndex][ix][0])}
            </td>
        )
    );

    // Generate the HTML for all CHART TABLEs. This is an ARRAY of TABLEs.
    const chartTableHtml = props.chartInfoArray.map((row, rowIndex) =>
        <table key={rowIndex} chartindex={rowIndex} className="chart-table">
        <colgroup>
            <col />
            {props.chartInfoArray[rowIndex].map((ci, ix) => <col key={ix} />)}
        </colgroup>
        <tbody>
            {props.chartSections.map((cs, ix) => <tr key={ix}><th>{cs.title}</th>{tdArray[rowIndex][ix]}</tr>)}
            <tr className="bar-nums"><th>MEASURE</th>{tdBarNumHtml[rowIndex]}</tr>
        </tbody>
        </table>
    );

    return chartTableHtml;
}

// Our property types
ReadOnlyChartSection.propTypes = {
    chartInfoArray: PropTypes.array.isRequired,
    chartSections: PropTypes.array.isRequired,
};

export default ReadOnlyChartSection;