import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Box, CircularProgress, Collapse, Button, IconButton, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    progress: {
        color: '#fff',
        margin: '2px 6px 0 2px',
    },
    icon: {
        marginRight: '4px',
    },
    textButton: {
        color: '#fff',
    },
}));

function SlidedownTray(props) {
    // Construct our classes
    const classes = useStyles();

    // Remember if we've scheduled an auto close of our tray...
    const [autoCloseTimeoutId, setAutoCloseTimeoutId] = useState(null);

    // Remember the content type that we are showing (and associating an autoClose for)
    const [autoCloseContentKey, setAutoCloseContentKey] = useState(null);

    // What to show in the main part of the tray?
    const mainMsg = (() => {
        // Has the content key changed (and do we have a timeout stored)?
        if (props.show && autoCloseTimeoutId && autoCloseContentKey !== props.contentKey) {
            // Stop the previous timeout
            clearTimeout(autoCloseTimeoutId);
            setAutoCloseTimeoutId(null);
        }

        // Show action in progress?
        if (props.inProgress) {
            return <><CircularProgress className={classes.progress} thickness={5} size={14} /><Typography>{props.message}</Typography></>
        }
        
        // Show action finished successfully?
        if (props.isSuccess) {
            // Should we auto close this?
            if (props.show && props.autoCloseOnSuccessIn > 0 && !autoCloseTimeoutId) {
                // Remember what content this timeout is associated with...
                setAutoCloseContentKey(props.contentKey);

                // Remember the timeout ID...
                setAutoCloseTimeoutId(setTimeout(() => {
                    // Close our tray
                    props.onClose();
                
                    // Remove our timeout ID
                    setAutoCloseTimeoutId(null);
                }, props.autoCloseOnSuccessIn*1000));
            }

            return <><CheckIcon className={classes.icon} fontSize='small'/><Typography>{props.message}</Typography></>
        }

        // Show action finished in error?
        return <><ErrorOutlineIcon className={classes.icon} fontSize='small' /><Typography>{props.message}</Typography></>
    })();

    return (
        <Collapse in={props.show}>
            <Box className={classes.box} p={1} color="white" bgcolor={props.inProgress || props.isSuccess ? "info.main" : "error.main"} display="flex">
                <Box flexGrow={1} alignItems="center" display="flex">
                    {mainMsg}
                </Box>
                { props.addCloseIcon && 
                <Box>
                    { props.addTextButton &&
                        <Button className={classes.textButton} onClick={props.onTextButtonClick} size="small">{props.textButton}</Button>
                    }
                    <IconButton size="small" onClick={props.onClose}>
                        <CloseIcon className={classes.closeIcon} fontSize="inherit" />
                    </IconButton>
                </Box>
                }
            </Box>
        </Collapse>
    );
}

SlidedownTray.propTypes = {
    addCloseIcon: PropTypes.bool,
    addTextButton: PropTypes.bool,
    autoCloseOnSuccessIn: PropTypes.number,
    contentKey: PropTypes.string,
    inProgress: PropTypes.bool,
    isSuccess: PropTypes.bool,
    message: PropTypes.string,
    onClose: PropTypes.func,
    onTextButtonClick: PropTypes.func,
    show: PropTypes.bool.isRequired,
    textButton: PropTypes.string,
};

export default SlidedownTray;