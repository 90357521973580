// Internal Modules
import {
    SET_EDIT_MODE, CANCEL_EDIT, DISABLE_SONG_SAVE,
    SAVE_SONG_STARTED, SAVE_SONG_SUCCESS, SAVE_SONG_FAILED, CREATE_CHART,
    GET_CHART_STARTED, GET_CHART_SUCCESS, GET_CHART_FAILED,
    SELECT_FOLDER, CLOSE_NAVBARSDT, CLOSE_SHARING_DLG,
    GET_CHART_SHARING_STARTED, GET_CHART_SHARING_SUCCESS, GET_CHART_SHARING_FAILED,
    SHARING_ADD_USER, SHARING_DELETE_USER, SHARING_REDUCE_USER, SHARING_RESET,
    PATCH_CHART_SHARING_STARTED, PATCH_CHART_SHARING_SUCCESS, PATCH_CHART_SHARING_FAILED,
    DELETE_CHART_STARTED, DELETE_CHART_SUCCESS, DELETE_CHART_FAILED,
    LOGOUT_STARTED, LOGOUT_SUCCESS, LOGOUT_FAILED, RESOLVE_OUTDATED_VERSION,
    SONG_LIST_CHANGE_SORT,
    SONG_DOWNLOAD_STARTED,
    SONG_DOWNLOAD_FAILED,
    SONG_DOWNLOAD_SUCCESS,
} from '../actions';

import {
    EDIT_MODE_EDITING, EDIT_MODE_SAVING, EDIT_MODE_VIEWING
} from '../editMode';

import {reduceAccessRight, hasMoreAccess, accessRoleMap, AT_NONE} from '../accessType';

const FLD_ERROR_SECTIONS = 'errorSections';
const FLD_HAS_EDIT_ERRORS = 'isSavingDisabled';
const FLD_IS_EDITING = 'isEditing';
const FLD_IS_SAVING = 'isSaving';
const FLD_MODE_VERB = 'editMode';
const FLD_NAVBARSDT_CONTENTKEY = 'navBarSdtContentKey';
const FLD_NAVBARSDT_INPROGRESS = 'navBarSdtInProgress';
const FLD_NAVBARSDT_ISSUCCESS = 'navBarSdtIsSuccess';
const FLD_NAVBARSDT_MESSAGE = 'navBarSdtMessage';
const FLD_NAVBARSDT_SHOW = 'navBarSdtShow';
const FLD_SAVE_SONG_ERRCODE = 'saveSongErrCode';
const FLD_SHARING_ADD_USER_MSG = 'sharingAddUserMessage';
const FLD_SHARING_BTN_ENABLED = 'sharingBtnEnabled';
const FLD_SHARING_DLG_SHOW = 'sharingDlgShow';
const FLD_SHARING_SETTINGS = 'sharingSettings';
const FLD_SHARING_SETTINGS_DIFF = 'sharingSettingsDiff';
const FLD_SHARING_SETTINGS_ORIG = 'sharingSettingsOrig';
const FLD_SHARINGSDT_INPROGRESS = 'sharingSdtInProgress';
const FLD_SHARINGSDT_ISSUCCESS = 'sharingSdtIsSuccess';
const FLD_SHARINGSDT_MESSAGE = 'sharingSdtMessage';
const FLD_SHARINGSDT_SHOW = 'sharingSdtShow';
const FLD_SONG_LIST_SORT_FIELD = 'songListSortField';
const FLD_SONG_LIST_SORT_ORDER = 'songListSortOrder';

const initialState = {
    [FLD_IS_EDITING]: false,
    [FLD_IS_SAVING]: false,
    [FLD_HAS_EDIT_ERRORS]: false,
    [FLD_MODE_VERB]: EDIT_MODE_VIEWING,
    [FLD_ERROR_SECTIONS]: {},
    [FLD_NAVBARSDT_SHOW]: false,
    [FLD_SHARING_DLG_SHOW]: false,
    [FLD_SHARING_BTN_ENABLED]: true,
    [FLD_SHARINGSDT_SHOW]: false,
    [FLD_SONG_LIST_SORT_FIELD]: 'songTitle',
    [FLD_SONG_LIST_SORT_ORDER]: 'asc',
};

export function songModeReducer(state = initialState, action) {
    switch (action.type) {
        case SET_EDIT_MODE:
            return Object.assign({}, state, {
                [FLD_IS_EDITING]: true,
                [FLD_IS_SAVING]: false,
                [FLD_MODE_VERB]: EDIT_MODE_EDITING,
            });
        case DISABLE_SONG_SAVE:
            const aggregateErrors = Object.values(state[FLD_ERROR_SECTIONS]).reduce((bln, sErrs) => bln || sErrs, false);
            return Object.assign({}, state, {
                [FLD_HAS_EDIT_ERRORS]: aggregateErrors,
                [FLD_ERROR_SECTIONS]: Object.assign({}, state[FLD_ERROR_SECTIONS], {[action.section]: action.disable})
            });
        case CANCEL_EDIT:
            return Object.assign({}, state, {
                [FLD_IS_EDITING]: false,
                [FLD_IS_SAVING]: false,
                [FLD_MODE_VERB]: EDIT_MODE_VIEWING,
            });
        case RESOLVE_OUTDATED_VERSION:
            return Object.assign({}, state, {
                [FLD_IS_EDITING]: false,
                [FLD_IS_SAVING]: false,
                [FLD_MODE_VERB]: EDIT_MODE_VIEWING,
                [FLD_SAVE_SONG_ERRCODE]: null,
                [FLD_NAVBARSDT_SHOW]: true,
                [FLD_NAVBARSDT_CONTENTKEY]: `GetChart::${action.songTitle}`,
                [FLD_NAVBARSDT_INPROGRESS]: true,
                [FLD_NAVBARSDT_MESSAGE]: `Retrieving the latest version of '${action.songTitle}'...`
            });
        case SAVE_SONG_STARTED:
            return Object.assign({}, state, {
                [FLD_NAVBARSDT_SHOW]: true,
                [FLD_NAVBARSDT_INPROGRESS]: true,
                [FLD_NAVBARSDT_ISSUCCESS]: null,
                [FLD_NAVBARSDT_MESSAGE]: `Starting save of '${action.songTitle}'...`,
                [FLD_NAVBARSDT_CONTENTKEY]: `SaveSong::${action.songTitle}`,
                [FLD_IS_SAVING]: true,
                [FLD_MODE_VERB]: EDIT_MODE_SAVING,
                [FLD_SAVE_SONG_ERRCODE]: null,
            });
        case SAVE_SONG_SUCCESS:
            return Object.assign({}, state, {
                [FLD_NAVBARSDT_INPROGRESS]: false,
                [FLD_NAVBARSDT_ISSUCCESS]: true,
                [FLD_NAVBARSDT_MESSAGE]: `Successfully saved '${action.songInfo.songTitle}'! You are now viewing version ${action.songInfo.version}.`,
                [FLD_IS_EDITING]: false,
                [FLD_IS_SAVING]: false,
                [FLD_MODE_VERB]: EDIT_MODE_VIEWING,
            });
        case SAVE_SONG_FAILED:
            return Object.assign({}, state, {
                [FLD_NAVBARSDT_INPROGRESS]: false,
                [FLD_NAVBARSDT_ISSUCCESS]: false,
                [FLD_NAVBARSDT_MESSAGE]: `Failed to save '${action.songTitle}': ${action.error}`,
                [FLD_IS_SAVING]: false,
                [FLD_MODE_VERB]: EDIT_MODE_EDITING,
                [FLD_SAVE_SONG_ERRCODE]: action.code,
            });
        case CREATE_CHART:
            return Object.assign({}, state, {
                [FLD_IS_EDITING]: true,
                [FLD_IS_SAVING]: false,
                [FLD_MODE_VERB]: EDIT_MODE_EDITING,
                [FLD_HAS_EDIT_ERRORS]: true,
            });
        case GET_CHART_STARTED:
            return Object.assign({}, state, {
                [FLD_NAVBARSDT_SHOW]: true,
                [FLD_NAVBARSDT_CONTENTKEY]: `GetChart::${action.songTitle}`,
                [FLD_NAVBARSDT_INPROGRESS]: true,
                [FLD_NAVBARSDT_MESSAGE]: `Retrieving the latest version of '${action.songTitle}'...`
            });
        case GET_CHART_SUCCESS:
            return Object.assign({}, state, {
                [FLD_NAVBARSDT_INPROGRESS]: false,
                [FLD_NAVBARSDT_ISSUCCESS]: true,
                [FLD_NAVBARSDT_MESSAGE]: `Successfully loaded '${action.chart.songTitle}'.`,
            });
        case GET_CHART_FAILED:
            return Object.assign({}, state, {
                [FLD_NAVBARSDT_INPROGRESS]: false,
                [FLD_NAVBARSDT_ISSUCCESS]: false,
                [FLD_NAVBARSDT_MESSAGE]: `Failed to get '${action.songTitle}': ${action.error}`,
            });
        case GET_CHART_SHARING_STARTED:
            return Object.assign({}, state, {
                [FLD_NAVBARSDT_SHOW]: true,
                [FLD_NAVBARSDT_CONTENTKEY]: `GetChartSharing::${action.songTitle}`,
                [FLD_NAVBARSDT_INPROGRESS]: true,
                [FLD_NAVBARSDT_MESSAGE]: `Retrieving the sharing settings for '${action.songTitle}'...`,
                [FLD_SHARING_SETTINGS]: [],
                [FLD_SHARING_SETTINGS_ORIG]: [],
                [FLD_SHARING_SETTINGS_DIFF]: {},
            });
        case GET_CHART_SHARING_SUCCESS:
            return Object.assign({}, state, {
                [FLD_NAVBARSDT_INPROGRESS]: false,
                [FLD_NAVBARSDT_ISSUCCESS]: true,
                [FLD_NAVBARSDT_MESSAGE]: `Successfully loaded sharing settings for '${action.songTitle}'.`,
                [FLD_SHARING_DLG_SHOW]: true,
                [FLD_SHARINGSDT_SHOW]: false,
                [FLD_SHARING_SETTINGS]: action.arrSharing,
                [FLD_SHARING_SETTINGS_ORIG]: action.arrSharing,
                [FLD_SHARING_SETTINGS_DIFF]: {},
                [FLD_SHARING_BTN_ENABLED]: false,
            });
        case GET_CHART_SHARING_FAILED:
            return Object.assign({}, state, {
                [FLD_NAVBARSDT_INPROGRESS]: false,
                [FLD_NAVBARSDT_ISSUCCESS]: false,
                [FLD_NAVBARSDT_MESSAGE]: `Failed to get sharing settings for '${action.songTitle}': ${action.error}`,
            });
        case DELETE_CHART_STARTED:
            return Object.assign({}, state, {
                [FLD_NAVBARSDT_SHOW]: true,
                [FLD_NAVBARSDT_CONTENTKEY]: `DeleteChart::${action.songTitle}`,
                [FLD_NAVBARSDT_INPROGRESS]: true,
                [FLD_NAVBARSDT_MESSAGE]: `Deleting the song '${action.songTitle}'...`,
            });
        case DELETE_CHART_SUCCESS:
            return Object.assign({}, state, {
                [FLD_NAVBARSDT_INPROGRESS]: false,
                [FLD_NAVBARSDT_ISSUCCESS]: true,
                [FLD_NAVBARSDT_MESSAGE]: `Successfully deleted '${action.songTitle}'.`,
            });
        case DELETE_CHART_FAILED:
            return Object.assign({}, state, {
                [FLD_NAVBARSDT_INPROGRESS]: false,
                [FLD_NAVBARSDT_ISSUCCESS]: false,
                [FLD_NAVBARSDT_MESSAGE]: `Unable to delete '${action.songTitle}': ${action.error}`,
            });
        case SELECT_FOLDER:
            return Object.assign({}, state, {
                [FLD_NAVBARSDT_SHOW]: false,
            });
        case CLOSE_NAVBARSDT:
            return Object.assign({}, state, {
                [FLD_NAVBARSDT_SHOW]: false,
            });
        case CLOSE_SHARING_DLG:
            return Object.assign({}, state, {
                [FLD_SHARING_DLG_SHOW]: false,
                [FLD_SHARING_BTN_ENABLED]: true,
            });
        case SHARING_ADD_USER:
            // Is the already in the list?
            let addUserMessage = '';
            const
                oldSettings = state[FLD_SHARING_SETTINGS],
                arrUserSettingAlreadyExists = oldSettings.filter(si => si.email === action.email),
                userSettingAlreadyExists = arrUserSettingAlreadyExists.length > 0,
                existingUserAccess = userSettingAlreadyExists && arrUserSettingAlreadyExists[0].accessType,
                arrOrigAt = state[FLD_SHARING_SETTINGS_ORIG].filter(si => si.email === action.email),
                origAt = arrOrigAt.length && arrOrigAt[0].accessType,
                elevatingAccess = userSettingAlreadyExists && hasMoreAccess(action.accessType, existingUserAccess),
                mapSettings = () => oldSettings.map(si => {
                    // Is it our user?
                    if (si.email === action.email) {
                        // Is the user Elevating Access for this user?
                        if (elevatingAccess) {
                            return Object.assign({}, si, {email: action.email, accessType: action.accessType});
                        }

                        // Cannot add if not elevating...
                        addUserMessage = `Cannot add user: ${action.email}: already set as ${accessRoleMap[existingUserAccess]}`;
                    }
                    return si;
                }),
                appendSettings = () => oldSettings.concat({email: action.email, accessType: action.accessType}),
                newSettings = userSettingAlreadyExists ? mapSettings() : appendSettings(),
                oldDiff = state[FLD_SHARING_SETTINGS_DIFF],
                arrUserDiffAlreadyExists = Object.keys(oldDiff).filter(k => k === action.email),
                userDiffAlreadyExists = arrUserDiffAlreadyExists.length > 0,
                reduceDiff = () => Object.entries(oldDiff).reduce((hsh, [email, atObj]) => {
                    // Is this our user?
                    if (email === action.email) {
                        if (elevatingAccess && origAt !== action.accessType) {
                            hsh[email] = {was: origAt, now: action.accessType};
                        }
                    } else {
                        hsh[email] = atObj;
                    }
                    return hsh;
                }, {}),
                appendDiff = () => Object.assign({}, oldDiff, {
                    [action.email]: {was: AT_NONE, now: action.accessType},
                }),
                newDiff = addUserMessage ? oldDiff : (userDiffAlreadyExists ? reduceDiff() : appendDiff());

            // CURRENTLY ASSUMES USER DOES NOT ALREADY EXIST!
            return Object.assign({}, state, {
                [FLD_SHARING_SETTINGS]: newSettings,
                [FLD_SHARING_SETTINGS_DIFF]: newDiff,
                [FLD_SHARING_ADD_USER_MSG]: addUserMessage,
            });
        case SHARING_DELETE_USER:
            {
                const arrWas = state[FLD_SHARING_SETTINGS].filter(si => si.email === action.email),
                    was = arrWas.length === 1 && arrWas.accessType;

                return Object.assign({}, state, {
                    [FLD_SHARING_SETTINGS]: state[FLD_SHARING_SETTINGS].filter(si => si.email !== action.email),
                    [FLD_SHARING_SETTINGS_DIFF]: Object.assign({}, state[FLD_SHARING_SETTINGS_DIFF], {[action.email]: {was, now: AT_NONE}})
                });
            }
        case SHARING_REDUCE_USER:
            {
                const oldSettings = state[FLD_SHARING_SETTINGS],
                    arrUserSettingAlreadyExists = oldSettings.filter(si => si.email === action.email),
                    userSettingAlreadyExists = arrUserSettingAlreadyExists.length > 0,
                    existingUserAccess = userSettingAlreadyExists && arrUserSettingAlreadyExists[0].accessType,
                    arrOrigAt = state[FLD_SHARING_SETTINGS_ORIG].filter(si => si.email === action.email),
                    origAt = (arrOrigAt.length && arrOrigAt[0].accessType) || AT_NONE,
                    now = reduceAccessRight(existingUserAccess),
                    newSettings = oldSettings.map(si => {
                        if (si.email === action.email) {
                            return Object.assign({}, si, {accessType: now});
                        } else {
                            return si;
                        }
                    }),
                    oldDiff = state[FLD_SHARING_SETTINGS_DIFF],
                    noChangeFromOrig = origAt === now,
                    reduceDiff = () => Object.entries(oldDiff).reduce((hsh, [k, v]) => {
                        if (k !== action.email) {
                            hsh[k] = v;
                        }
                        return hsh;
                    }, {}),
                    appendDiff = () => Object.assign({}, oldDiff, {[action.email]: {was: origAt, now}}),
                    newDiff = noChangeFromOrig ? reduceDiff() : appendDiff();

                return Object.assign({}, state, {
                    [FLD_SHARING_SETTINGS]: newSettings,
                    [FLD_SHARING_SETTINGS_DIFF]: newDiff,
                });
            }
        case SHARING_RESET:
            return Object.assign({}, state, {
                [FLD_SHARING_SETTINGS]: state[FLD_SHARING_SETTINGS_ORIG],
                [FLD_SHARING_SETTINGS_DIFF]: {},
                [FLD_SHARING_ADD_USER_MSG]: '',
            });
        case PATCH_CHART_SHARING_STARTED:
            return Object.assign({}, state, {
                [FLD_SHARINGSDT_SHOW]: true,
                [FLD_SHARINGSDT_INPROGRESS]: true,
                [FLD_SHARINGSDT_MESSAGE]: `Saving your sharing options for '${action.songTitle}'...`,
            });
        case PATCH_CHART_SHARING_SUCCESS:
            return Object.assign({}, state, {
                [FLD_SHARINGSDT_INPROGRESS]: false,
                [FLD_SHARINGSDT_ISSUCCESS]: true,
                [FLD_SHARINGSDT_MESSAGE]: `Successfully saved sharing options for '${action.songTitle}'.`,
                [FLD_SHARING_DLG_SHOW]: false,
                [FLD_SHARING_BTN_ENABLED]: true,
            });
        case PATCH_CHART_SHARING_FAILED:
            return Object.assign({}, state, {
                [FLD_SHARINGSDT_INPROGRESS]: false,
                [FLD_SHARINGSDT_ISSUCCESS]: false,
                [FLD_SHARINGSDT_MESSAGE]: `Failed to save sharing options for '${action.songTitle}': ${action.error}.`,
            });
        case LOGOUT_STARTED:
            return Object.assign({}, state, {
                [FLD_NAVBARSDT_SHOW]: true,
                [FLD_NAVBARSDT_CONTENTKEY]: `Logout`,
                [FLD_NAVBARSDT_INPROGRESS]: true,
                [FLD_NAVBARSDT_MESSAGE]: `Logging you out...`,
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                [FLD_NAVBARSDT_SHOW]: false,
                [FLD_NAVBARSDT_INPROGRESS]: false,
                [FLD_NAVBARSDT_ISSUCCESS]: true,
                [FLD_NAVBARSDT_MESSAGE]: null,
            });
        case LOGOUT_FAILED:
            return Object.assign({}, state, {
                [FLD_NAVBARSDT_INPROGRESS]: false,
                [FLD_NAVBARSDT_ISSUCCESS]: false,
                [FLD_NAVBARSDT_MESSAGE]: `Unable to log out you: ${action.error}`,
            });
        case SONG_LIST_CHANGE_SORT:
            return Object.assign({}, state, {
                [FLD_SONG_LIST_SORT_FIELD]: action.fieldName,
                [FLD_SONG_LIST_SORT_ORDER]: action.sortOrder,
            });
        case SONG_DOWNLOAD_STARTED:
            return Object.assign({}, state, {
                [FLD_NAVBARSDT_SHOW]: true,
                [FLD_NAVBARSDT_CONTENTKEY]: `DownloadSong::${action.songTitle}`,
                [FLD_NAVBARSDT_INPROGRESS]: true,
                [FLD_NAVBARSDT_MESSAGE]: `Starting the PDF download for '${action.songTitle}'...`
            });
        case SONG_DOWNLOAD_SUCCESS:
            return Object.assign({}, state, {
                [FLD_NAVBARSDT_SHOW]: false,
                [FLD_NAVBARSDT_INPROGRESS]: false,
                [FLD_NAVBARSDT_ISSUCCESS]: true,
                [FLD_NAVBARSDT_MESSAGE]: null,
            });
        case SONG_DOWNLOAD_FAILED:
            return Object.assign({}, state, {
                [FLD_NAVBARSDT_INPROGRESS]: false,
                [FLD_NAVBARSDT_ISSUCCESS]: false,
                [FLD_NAVBARSDT_MESSAGE]: `PDF download for '${action.songTitle}' failed: ${action.error}`
            });
        default:
            return state;
    }
}
