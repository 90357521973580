// Authentication Fields
import {
    AUTH_INIT_STARTED, AUTH_INIT_SUCCESS, AUTH_INIT_FAILED, 
} from '../actions';

// State Fields...
export const FLD_AUTH_INITIALIZING = 'authInitializing';
export const FLD_AUTH_INITERROR = 'authInitError';

// Authentication Reducer...
export function authReducer(state = {}, action) {
    switch (action.type) {
        case AUTH_INIT_STARTED:
            return Object.assign({}, state, {
                [FLD_AUTH_INITIALIZING]: true
            });
        case AUTH_INIT_SUCCESS: 
            return Object.assign({}, state, {
                [FLD_AUTH_INITIALIZING]: false
            });
        case AUTH_INIT_FAILED:
            return Object.assign({}, state, {
                [FLD_AUTH_INITIALIZING]: false,
                [FLD_AUTH_INITERROR]: action.error
            });
        default:
            return state;
    }
}

