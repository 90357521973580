// External Modules...
import {createSelector} from 'reselect';

// Internal Modules
import {
    GET_CHARTS_STARTED, GET_CHARTS_SUCCESS, GET_CHARTS_FAILED,
    GET_CHART_SUCCESS,
    SELECT_SONG, CHANGE_SONG_SECTION, SELECT_FOLDER, CREATE_CHART,
    CANCEL_EDIT, SAVE_SONG_SUCCESS, SAVE_SONG_INFO, DELETE_CHART_SUCCESS,
    LOGOUT_SUCCESS, PATCH_CHART_SHARING_SUCCESS,
} from '../actions';
import { AT_NONE } from '../accessType';

// Fields
export const FLD_CHART_ARRAY = 'arrCharts';
export const FLD_GET_CHARTS_ERROR = 'getChartsError';
export const FLD_GET_CHARTS_STARTED = 'requestingCharts';
export const FLD_SELECTED_SONG = 'selectedSong';
export const FLD_SELECTED_SONG_ID = 'selectedSongId';

const initialState = {
};

const chartInfoSelector = state => (state[FLD_SELECTED_SONG] || {}).chartInfo;
export const selectChartInfoArray = createSelector(chartInfoSelector,
    ci => (ci || []).reduce((arr, obj) => {
        // Append the object to the last element of the current array
        arr[arr.length-1].push(obj);

        // Should we break after this section?
        if (obj.break === true) {
          // Add a new empty array to the end
          arr.push([]);
        }

        return arr;
    }, [[]])
);

export function getChartsReducer(state = initialState, action) {
    const lastLoaded = Date.now();
    switch (action.type) {
        /* Retrieving a user's charts */
        case GET_CHARTS_STARTED:
            return Object.assign({}, state, {
                [FLD_GET_CHARTS_STARTED]: true,
                [FLD_CHART_ARRAY]: [],
                [FLD_GET_CHARTS_ERROR]: null,
            });
        case GET_CHARTS_SUCCESS:
            return Object.assign({}, state, {
                [FLD_GET_CHARTS_STARTED]: false,
                [FLD_CHART_ARRAY]: action.charts.map(si => Object.assign({}, si, {lastLoaded})),
            });
        case GET_CHARTS_FAILED:
            return Object.assign({}, state, {
                [FLD_GET_CHARTS_STARTED]: false,
                [FLD_GET_CHARTS_ERROR]: action.error,
            });
        case GET_CHART_SUCCESS:
            const chartId = action.chart.id;

            return Object.assign({}, state, {
                [FLD_CHART_ARRAY]: state[FLD_CHART_ARRAY] && state[FLD_CHART_ARRAY].length ? state[FLD_CHART_ARRAY].map(c => {
                    // Is this the song we just loaded?
                    if (c.id === chartId) {
                        return Object.assign({}, action.chart, {lastLoaded});
                    } else {
                        return c;
                    }
                }) : [Object.assign({}, action.chart, {lastLoaded})],
                [FLD_SELECTED_SONG_ID]: chartId,
                [FLD_SELECTED_SONG]: action.chart,
            });
        case SELECT_SONG:
            return Object.assign({}, state, {
                [FLD_SELECTED_SONG_ID]: action.songId,
                [FLD_SELECTED_SONG]: state[FLD_CHART_ARRAY].filter(c => c.id === action.songId)[0],
            });
        case SAVE_SONG_SUCCESS:
            // How to determine if the supplied song is new--if it's ID does not already exist...
            const newSongId = action.songInfo.id,
                newChart = state[FLD_CHART_ARRAY].filter(c => c.id === newSongId).length === 0,
                songInfo = Object.assign({}, action.songInfo, {lastLoaded});

            return Object.assign({}, state, {
                [FLD_CHART_ARRAY]: newChart ? state[FLD_CHART_ARRAY].concat(songInfo) : state[FLD_CHART_ARRAY].map(c => {
                    // Is this the song we are saving?
                    if (c.id === newSongId) {
                        return songInfo;
                    } else {
                        return c;
                    }
                }),
                [FLD_SELECTED_SONG_ID]: newSongId,
                [FLD_SELECTED_SONG]: songInfo,
            });
        case CHANGE_SONG_SECTION:
            // Construct an empty section...
            const emptySection = {notes: '', bars: 1, songForm: '', insertNew: true};

            // If this is an insertion, then we can create the new chartInfo array here...
            let newChartInfo;
            const existingChartInfo = state[FLD_SELECTED_SONG].chartInfo;
            if (action.action === 'insertEmpty') {
                newChartInfo = existingChartInfo.length === 0 ? [emptySection] :
                    (existingChartInfo.length === action.index ? existingChartInfo.concat(emptySection) : existingChartInfo.reduce((arr, ci, ix) => {
                        // Is this where we want to insert the new section?
                        if (ix === action.index) {
                            arr.push(emptySection);
                        }

                        // Add the existing section
                        arr.push(ci);

                        return arr;
                    }, []));
            } else if (action.action === 'break') {
                // Change the break status on a section...
                newChartInfo = existingChartInfo.reduce((arr, ci, ix) => {
                    if (ix === action.index) {
                        // Construct a new chart info section...
                        const newCi = Object.assign({}, ci, {break: true});

                        arr.push(newCi);
                    } else {
                        arr.push(ci);
                    }

                    return arr;
                }, [])
            } else if (action.action === 'join') {
                // Change the break status on a section...
                newChartInfo = existingChartInfo.reduce((arr, ci, ix) => {
                    if (ix === action.index) {
                        // Construct a new chart info section...
                        const newCi = Object.assign({}, ci);
                        delete newCi.break;

                        arr.push(newCi);
                    } else {
                        arr.push(ci);
                    }

                    return arr;
                }, [])
            } else {
                // If this is a replace or delete, modify the chartInfo array...
                newChartInfo = existingChartInfo.reduce((arr, ci, ix) => {
                    if (action.action === 'replace' && ix === action.index) {
                        arr.push(action.songSection);
                    } else if (action.action === 'delete' && ix === action.index) {
                        // Do nothing--don't include this element in the result
                    } else {
                        arr.push(ci);
                    }

                    return arr;
                }, [])
            }

            return Object.assign({}, state, {
                [FLD_SELECTED_SONG]: Object.assign({}, state[FLD_SELECTED_SONG], {chartInfo: newChartInfo})
            });
        case SAVE_SONG_INFO:
            return Object.assign({}, state, {
                [FLD_SELECTED_SONG]: Object.assign({}, state[FLD_SELECTED_SONG], action.songInfo)
            });
        case SELECT_FOLDER:
            return Object.assign({}, state, {
                [FLD_SELECTED_SONG_ID]: null,
                [FLD_SELECTED_SONG]: null,
            });
        case CREATE_CHART:
            // Empty song...
            const emptySong = {
                songTitle: '',
                songTitle_err: 'Empty',
                artistName: '',
                artistName_err: 'Empty',
                beatsPerMeasure: '',
                beatsPerMeasure_err: 'Empty',
                tempo: '',
                tempo_err: 'Empty',
                eachBeatIsANote: 4,
                summary: '',
                chartInfo: [{insertNew: true, songForm: '', bars: 1, notes: ''}]
            };
            return Object.assign({}, state, {
                [FLD_SELECTED_SONG]: emptySong,
            });
        case CANCEL_EDIT:
            const savedSong = state[FLD_CHART_ARRAY].filter(si => si.id === state[FLD_SELECTED_SONG_ID])[0];

            return Object.assign({}, state, {
                [FLD_SELECTED_SONG]: savedSong,
            });
        case DELETE_CHART_SUCCESS:
            return Object.assign({}, state, {
                [FLD_CHART_ARRAY]: state[FLD_CHART_ARRAY].filter(si => si.id !== action.chartId),
                [FLD_SELECTED_SONG]: null,
                [FLD_SELECTED_SONG_ID]: null,
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                [FLD_CHART_ARRAY]: [],
                [FLD_SELECTED_SONG]: null,
                [FLD_SELECTED_SONG_ID]: null,
                [FLD_GET_CHARTS_STARTED]: undefined,
            });
        case PATCH_CHART_SHARING_SUCCESS:
            const newArrayChart = state[FLD_CHART_ARRAY].reduce((arr, si) => {
                    // Are we looking at the song that just had its sharing settings changed?
                    if (si.id === action.chartId) {
                        // Is the new access type NONE?
                        if (action.acg === AT_NONE) {
                            // Do nothing!
                        } else {
                            // Add to the array with a new access granted flag..
                            arr.push(Object.assign({}, si, {acg: action.acg}));
                        }
                    } else {
                        arr.push(si);
                    }
                    return arr;
                }, []),
                newSelectedSong = action.acg === AT_NONE ? null : Object.assign({}, state[FLD_SELECTED_SONG], {acg: action.acg}),
                newSelectedSongId = action.acg === AT_NONE ? null : state[FLD_SELECTED_SONG_ID];

            return Object.assign({}, state, {
                [FLD_CHART_ARRAY]: newArrayChart,
                [FLD_SELECTED_SONG]: newSelectedSong,
                [FLD_SELECTED_SONG_ID]: newSelectedSongId,
            });
        default:
            return state;
    }
}
