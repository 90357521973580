// External Modules
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// Internal Modules
import './Song.scss';
import songDuration from './songDuration';
import {replaceNewlineWithBreak} from './util';


// Other Unicode characters
// const CHAR_BLACK_CIRCLE = '\u25cf';
const CHAR_WHITE_CIRCLE = '\u25cb';

// What character to show between elements of the song
const sepChar = CHAR_WHITE_CIRCLE;

// Unicode characters for notes
const CHAR_HALF_NOTE = '\ud834\udd5e';
const CHAR_QUARTER_NOTE = '\u2669';
const CHAR_EIGHTH_NOTE = '\u266a';
const CHAR_SIXTEENTH_NOTE = '\ud834\udd61';

// Our function component
function ReadOnlySongInfo(props) {
    // Compute the number of seconds in one beat
    const oneBeatIs = 60 / props.tempo;

    // Compute the overall song duration (as a string), num of bars
    const {str: duration, bars: numOfBars} = songDuration(props);

    // Compute the note character to use which represents one beat
    const oneBeatNote = (() => {
        if (props.eachBeatIsANote === 2) {
            return CHAR_HALF_NOTE;
        }

        if (props.eachBeatIsANote === 4) {
            return CHAR_QUARTER_NOTE;
        }

        if (props.eachBeatIsANote === 8) {
            return CHAR_EIGHTH_NOTE;
        }

        if (props.eachBeatIsANote === 16) {
            return CHAR_SIXTEENTH_NOTE;
        }

        alert(`Unexpected value for 'eachBeatIsANote': ${props.eachBeatIsANote}`);
    })();

    return (
        <>
            <div className="song-title">
                {props.songTitle}
                {props.version &&
                    <span className="version">[v{props.version}]</span>
                }
            </div>
            <div className="artist-name">
                <span className="label">By: </span>
                {props.artistName}
            </div>
            {props.creationDate && props.showDates &&
            <div className="creation-date">
                <span className="label">Created: </span>
                {moment.unix(props.creationDate).format(props.dateFormat)}
                <span className="creation-user"> ({props.creationUser})</span>
            </div>
            }
            {props.lastModificationDate && props.showDates &&
            <div className="last-mod-date">
                <span className="label">Changed: </span>
                {moment.unix(props.lastModificationDate).format(props.dateFormat)}
                <span className="last-mod-user"> ({props.lastModificationUser})</span>
            </div>
            }
            <div className="label-value-section">
                {props.showTimeInfo &&
                    <>
                        { props?.songKey &&
                            <div className="label-value">
                                <span className="label">Song Key:</span>
                                <span className="value">{props.songKey}</span>
                            </div>
                        }
                        { props?.songKey &&
                            <span className="label-value-sep">{sepChar}</span>
                        }
                        <div className="label-value time-signature">
                            <span className="label">Time Sig:</span>
                            <span className="time-sig-beats-per-measure">{props.beatsPerMeasure}</span>/<span className="time-sig-note-value">{props.eachBeatIsANote}</span>
                        </div>
                        <span className="label-value-sep">{sepChar}</span>
                        <div className="label-value tempo">
                            <span className="label">Tempo:</span>
                            <span className="value">{props.tempo}</span>
                        </div>
                        <span className="label-value-sep">{sepChar}</span>
                        <div className="label-value song-duration">
                            <span className="label">Duration:</span>
                            <span className="value">{duration}</span>
                        </div>
                    </>
                }
                {props.showTimeInfo && props.showRecInfo &&
                    <span className="label-value-sep">{sepChar}</span>
                }
                {props.showRecInfo &&
                    <>
                        <div className="label-value bars">
                            <span className="label"># of Bars:</span>
                            <span className="value">{numOfBars}</span>
                        </div>
                        <span className="label-value-sep">{sepChar}</span>
                        <div className="label-value beat-duration">
                            <span className="label">A {oneBeatNote} is:</span>
                            <span className="value">{oneBeatIs.toFixed(3)}</span>&nbsp;s
                        </div>
                        <span className="label-value-sep">{sepChar}</span>
                        <div className="label-value bar-duration">
                            <span className="label">1 Bar is:</span>
                            <span className="value">{(props.beatsPerMeasure*oneBeatIs).toFixed(3)}</span>&nbsp;s
                        </div>
                    </>
                }
                {props.summary &&
                    <div className="summary" dangerouslySetInnerHTML={{__html: replaceNewlineWithBreak(props.summary)}}>
                    </div>
                }
            </div>
        </>
    );
}

// Component properties
ReadOnlySongInfo.propTypes = {
    artistName: PropTypes.string.isRequired,
    beatsPerMeasure: PropTypes.number.isRequired,
    chartInfo: PropTypes.array.isRequired,
    creationDate: PropTypes.number.isRequired,
    creationUser: PropTypes.string.isRequired,
    dateFormat: PropTypes.string.isRequired,
    eachBeatIsANote: PropTypes.number.isRequired,
    lastModificationDate: PropTypes.number,
    lastModificationUser: PropTypes.string,
    showDates: PropTypes.bool.isRequired,
    showRecInfo: PropTypes.bool.isRequired,
    showTimeInfo: PropTypes.bool.isRequired,
    songTitle: PropTypes.string.isRequired,
    summary: PropTypes.string,
    tempo: PropTypes.number.isRequired,
    version: PropTypes.number.isRequired,
};

export default ReadOnlySongInfo;
