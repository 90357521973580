// External modules
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

// Internal modules
import {
    GET_SESSION_STARTED,
    GET_CHARTS_STARTED,
    SAVE_SONG_STARTED,
    GET_CHART_STARTED,
    GET_CHART_SHARING_STARTED,
    PATCH_CHART_SHARING_STARTED,
    DELETE_CHART_STARTED,
    RESOLVE_OUTDATED_VERSION,
    SONG_DOWNLOAD_STARTED,
    getSessionFailed,
    getSessionSuccessful,
    getChartsFailed,
    getChartsSuccessful,
    getChartSharingFailed,
    getChartSharingSuccessful,
    getChartFailed,
    getChartSuccessful,
    saveSongFailed,
    saveSongSuccessful,
    patchChartSharingFailed,
    patchChartSharingSuccessful,
    deleteChartFailed,
    deleteChartSuccessful,
    songDownloadFailed,
    songDownloadSuccessful,
} from './actions';
import { getSelectedSong } from './selectors';
import { AT_NONE, isValidAccessType } from './accessType';
import { getApiUrl } from './util';

// Helper fn
function isObject(obj) {
    return obj !== undefined && obj !== null && obj.constructor === Object;
}

// Worker Saga: Check (Get) Session
function* getSession(action) {
    try {
        // Invoke the GET /session API
        const response = yield call(fetch, getApiUrl('/session'), {
            credentials: 'include',
            method: 'GET',
        });

        // Did the response fail?
        if (!response.ok) {
            // Indicate that the call failed...
            yield put(getSessionFailed(response.statusText));
        } else {
            // Get the basic profile JSON
            const basicProfile = yield response.json();

            // Is it not an object?
            if (!isObject(basicProfile)) {
                // Indicate unexpected response
                yield put(getSessionFailed('Response is not an object'));
            } else {
                yield put(getSessionSuccessful(basicProfile));
            }
        }
    } catch (err) {
        yield put(getSessionFailed(err.message));
    }
}

// worker Saga: fetch charts
function* fetchCharts(action) {
    try {
        // Invoke the GET /charts API
        const response = yield call(fetch, getApiUrl('/charts'), {
            credentials: 'include',
            method: 'GET',
        });

        // Did the response fail?
        if (!response.ok) {
            // Indicate that the call failed
            yield put(getChartsFailed(response.statusText));
        } else {
            // Get the Charts JSON
            const charts = yield response.json();

            // Is it not an array?
            if (!Array.isArray(charts)) {
                // Got some unexpected response back
                yield put(getChartsFailed('Response is not an array'));
            } else {
                // Indicate the action that the charts have loaded...
                yield put(getChartsSuccessful(charts));
            }
        }
    } catch (err) {
        yield put(getChartsFailed(err.message));
    }
}

// Worker Saga: Save chart (song)
function* saveSong(action) {
    let songTitle;
    try {
        // Get the selected song from the state...
        const selectedSong = yield select(getSelectedSong);

        // Are we saving a new chart or an existing one?
        const existingChart = !!selectedSong.id;

        // Trim artist name and song title
        const chartJson = Object.assign({}, selectedSong, {
            artistName: selectedSong.artistName.trim(),
            songTitle: selectedSong.songTitle.trim(),
            lastLoaded: undefined,
            acg: undefined,
        });

        // Get the song title
        songTitle = chartJson.songTitle;

        let response;
        const options = {
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(chartJson),
        };
        if (!existingChart) {
            // Invoke the POST /charts API
            response = yield call(
                fetch,
                getApiUrl('/charts'),
                Object.assign({}, options, { method: 'POST' })
            );
        } else {
            // Invoke the PUT /chart/{id} API
            response = yield call(
                fetch,
                getApiUrl(`/chart/${chartJson.id}`),
                Object.assign({}, options, { method: 'PUT' })
            );
        }

        // Did the response fail?
        if (!response.ok) {
            // Get our generated error message
            const errMsg = response.headers.get('X-ErrMsg'),
                errCode = +response.headers.get('X-ErrCode');

            // Indicate that the call failed
            yield put(
                saveSongFailed(
                    errMsg || response.statusText,
                    errCode,
                    songTitle
                )
            );
        } else {
            // Get the Chart JSON
            const chart = yield response.json();

            // Is it not an object?
            if (chart !== Object(chart)) {
                // Got some unexpected response back
                yield put(
                    saveSongFailed('Response is not an object', null, songTitle)
                );
            } else {
                // Indicate the action that the chart was saved
                yield put(saveSongSuccessful(chart));
            }
        }
    } catch (err) {
        // Indicate that the call failed
        yield put(saveSongFailed(err.message, -1, songTitle));
    }
}

// Worker Saga: Get a specific chart
function* getChart(action) {
    let songTitle;
    try {
        const options = {
            credentials: 'include',
        };

        // What is the name of the song that we are requesting?
        songTitle = action.songTitle;

        // Invoke the GET /chart/{id} API
        const response = yield call(
            fetch,
            getApiUrl(`/chart/${action.songId}`),
            Object.assign({}, options, { method: 'GET' })
        );

        // Did the response fail?
        if (!response.ok) {
            // Get our generated error message
            const errMsg = response.headers.get('X-ErrMsg');

            // Indicate that the call failed
            yield put(getChartFailed(errMsg || response.statusText, songTitle));
        } else {
            // Get the Chart JSON
            const chart = yield response.json();

            // Is it not an object?
            if (chart !== Object(chart)) {
                // Got some unexpected response back
                yield put(
                    getChartFailed('Response is not an object', songTitle)
                );
            } else {
                // Indicate the action that the chart was saved
                yield put(getChartSuccessful(chart));
            }
        }
    } catch (err) {
        // Indicate that the call failed
        yield put(getChartFailed(err.message, songTitle));
    }
}

// Worker Saga: Get a specific chart's sharing settings
function* getChartSharing(action) {
    let songTitle;
    try {
        const options = {
            credentials: 'include',
        };

        // What is the name of the song that we are requesting?
        songTitle = action.songTitle;
        const { chartId } = action;

        // Invoke the GET /chart/{id}/sharing API
        const response = yield call(
            fetch,
            getApiUrl(`/chart/${chartId}/sharing`),
            Object.assign({}, options, { method: 'GET' })
        );

        // Did the response fail?
        if (!response.ok) {
            // Get our generated error message
            const errMsg = response.headers.get('X-ErrMsg');

            // Indicate that the call failed
            yield put(
                getChartSharingFailed(errMsg || response.statusText, songTitle)
            );
        } else {
            // Get the Chart Sharing JSON Array
            const arrSharing = yield response.json();

            // Is it not an array?
            if (!Array.isArray(arrSharing)) {
                // Got some unexpected response back
                yield put(
                    getChartSharingFailed('Response is not an array', songTitle)
                );
            } else {
                // Indicate the action that the chart sharing was retrieved
                yield put(
                    getChartSharingSuccessful(chartId, songTitle, arrSharing)
                );
            }
        }
    } catch (err) {
        // Indicate that the call failed
        yield put(getChartSharingFailed(err.message, songTitle));
    }
}

// Worker Saga: Patch a specific chart's sharing settings
function* patchChartSharing(action) {
    let songTitle;
    try {
        // What is the name of the song that we are requesting?
        songTitle = action.songTitle;
        const { chartId, hshSharingDiffs } = action;

        // Construct the correct PATCH json
        const patchJson = Object.entries(hshSharingDiffs).map(
            ([email, atObj]) => {
                const { was, now } = atObj;

                // If the access type is NONE, then remove...
                if (now === AT_NONE) {
                    return { op: 'remove', path: email };
                }
                // If we have a prior access type, then assume it is a replace
                if (was !== AT_NONE) {
                    return { op: 'replace', path: email, value: now };
                }
                return { op: 'add', path: email, value: now };
            }
        );

        const options = {
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(patchJson),
        };

        // Invoke the PATCH /chart/{id}/sharing API
        const response = yield call(
            fetch,
            getApiUrl(`/chart/${chartId}/sharing`),
            Object.assign({}, options, { method: 'PATCH' })
        );

        // Did the response fail?
        if (!response.ok) {
            // Get our generated error message
            const errMsg = response.headers.get('X-ErrMsg');

            // Indicate that the call failed
            yield put(
                patchChartSharingFailed(
                    errMsg || response.statusText,
                    songTitle
                )
            );
        } else {
            // Get the new resolved access granted for the caller
            const acg = yield response.text();

            // If it is not one of the expected values, complain...
            if (!isValidAccessType(acg)) {
                yield put(
                    patchChartSharingFailed(
                        `Unexpected access type returned: ${acg}`,
                        songTitle
                    )
                );
            } else {
                // Indicate the action was successful
                yield put(patchChartSharingSuccessful(chartId, songTitle, acg));
            }
        }
    } catch (err) {
        // Indicate that the call failed
        yield put(patchChartSharingFailed(err.message, songTitle));
    }
}

// Worker Saga: Delete a specific chart
function* deleteChart(action) {
    const { chartId, songTitle, history, toPath } = action;
    try {
        const options = {
            credentials: 'include',
        };

        // Invoke the PATCH /chart/{id}/sharing API
        const response = yield call(
            fetch,
            getApiUrl(`/chart/${chartId}`),
            Object.assign({}, options, { method: 'DELETE' })
        );

        // Did the response fail?
        if (!response.ok) {
            // Get our generated error message
            const errMsg = response.headers.get('X-ErrMsg');

            // Indicate that the call failed
            yield put(
                deleteChartFailed(errMsg || response.statusText, songTitle)
            );
        } else {
            // Indicate the action was successful
            yield put(deleteChartSuccessful(chartId, songTitle));

            // If we have a history and a toPath, go to it now!
            if (history && toPath) {
                history.replace(toPath);
            }
        }
    } catch (err) {
        // Indicate that the call failed
        yield put(deleteChartFailed(err.message, songTitle));
    }
}

// Worker Saga: Start a Song Download
function* downloadSong(action) {
    const { chartId, songTitle, fieldsToShow } = action;
    try {
        const options = {
            credentials: 'include',
        };

        // Invoke the GET /chart/{id}/pdf API
        const response = yield call(
            fetch,
            getApiUrl(`/chart/${chartId}/pdf?fts=${fieldsToShow.join(',')}`),
            Object.assign({}, options, { method: 'GET' })
        );

        // Did the response fail?
        if (!response.ok) {
            // Get our generated error message
            const errMsg = response.headers.get('X-ErrMsg');

            // Indicate that the call failed
            yield put(
                songDownloadFailed(errMsg || response.statusText, songTitle)
            );
        } else {
            // Get the raw response as an array buffer
            let buffer = yield response.arrayBuffer();

            // Diagnostics
            // console.log({ buffer });

            // Get the first four bytes
            const hdr = new Uint8Array(buffer.slice(0, 4));

            // Diagnostics
            // console.log({ hdr });

            // Get the first four bytes as a string
            const hdrAsStr = String.fromCharCode(
                hdr[0],
                hdr[1],
                hdr[2],
                hdr[3]
            );

            // Diagnostics
            // console.log({ hdrAsStr });

            // Is this is the BASE64 encoded content?
            if (hdrAsStr === btoa('%PDF').substring(0, 4)) {
                // Get a UInt 8 array from the buffer...
                const uint8Arr = new Uint8Array(buffer);

                // Get the string in its entirety
                const arrChars = [];
                for (let i = 0; i < uint8Arr.length; i++) {
                    // Get the 8 bit unsigned representation
                    const intChar = uint8Arr[i];

                    // Add the character equivalent to our array...
                    arrChars.push(String.fromCharCode(intChar));
                }

                // Construct a string from the array
                const bufferAsStr = arrChars.join('');

                // Diagnostics
                // console.log({bufferAsStr});

                // Reassign the buffer
                buffer = Uint8Array.from(atob(bufferAsStr), c =>
                    c.charCodeAt(0)
                );
            }

            // const pdfBlob = yield response.blob();
            const pdfBlob = new Blob([buffer], { type: 'application/pdf' });

            // Diagnostics
            // console.log({ pdfBlob });

            // Create the download (data) URL
            const pdfUrl = window.URL.createObjectURL(pdfBlob);

            // Diagnostics
            // console.log({ pdfUrl });

            // Construct the name of our PDF file
            const pdfFileName = `${songTitle}.pdf`;

            // Create a link to download the data
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.setAttribute('download', pdfFileName);
            document.body.appendChild(link);

            // Click the link to trigger the file download
            link.click();

            // Now release the Object URL
            window.URL.revokeObjectURL(pdfUrl);

            // Indicate the action was successful
            yield put(songDownloadSuccessful(chartId, songTitle));

            // If we have a history and a toPath, go to it now!
            // if (history && toPath) {
            //     history.replace(toPath);
            // }
        }
    } catch (err) {
        // Indicate that the call failed
        yield put(songDownloadFailed(err.message, songTitle));
    }
}

function* watchGetSessionStarted() {
    yield takeEvery(GET_SESSION_STARTED, getSession);
}

function* watchGetChartsStarted() {
    yield takeEvery(GET_CHARTS_STARTED, fetchCharts);
}

function* watchSaveChartStarted() {
    yield takeEvery(SAVE_SONG_STARTED, saveSong);
}

function* watchGetChartStarted() {
    yield takeEvery(GET_CHART_STARTED, getChart);
}

function* watchResolveOutdatedVersion() {
    yield takeEvery(RESOLVE_OUTDATED_VERSION, getChart);
}

function* watchGetChartSharingStarted() {
    yield takeEvery(GET_CHART_SHARING_STARTED, getChartSharing);
}

function* watchPatchChartSharingStarted() {
    yield takeEvery(PATCH_CHART_SHARING_STARTED, patchChartSharing);
}

function* watchDeleteChartStarted() {
    yield takeEvery(DELETE_CHART_STARTED, deleteChart);
}

function* watchDownloadSongStarted() {
    yield takeEvery(SONG_DOWNLOAD_STARTED, downloadSong);
}

export default function* rootSaga() {
    yield all([
        watchGetSessionStarted(),
        watchGetChartsStarted(),
        watchSaveChartStarted(),
        watchGetChartStarted(),
        watchResolveOutdatedVersion(),
        watchGetChartSharingStarted(),
        watchPatchChartSharingStarted(),
        watchDeleteChartStarted(),
        watchDownloadSongStarted(),
    ]);
}
