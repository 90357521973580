export default function songDuration(songInfo) {
    // Missing fields?
    if (!songInfo.tempo || !songInfo.chartInfo || !songInfo.chartInfo.length || !songInfo.beatsPerMeasure) {
    return {str: '??:??', sec: NaN, bars: NaN};
    }
    
    // Compute the number of seconds in one beat
    const oneBeatIs = 60 / songInfo.tempo;

    // Compute the number of bars...
    const numOfBars = songInfo.chartInfo.reduce((res, obj) => (res + (obj.bars || 1)), 0);

    // Compute the overall song duration (in seconds)
    const songDuration = numOfBars * oneBeatIs * songInfo.beatsPerMeasure;

    // Compute the overall song duration (in minutes and seconds)
    const songDurationMin = Math.floor(songDuration / 60);
    const songDurationSec = (Math.floor(songDuration % 60)+"").padStart(2, '0');

    return {str: `${songDurationMin}:${songDurationSec}`, sec: songDuration, bars: numOfBars};
}
