// External modules...
import React, {useReducer} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {TextField, InputLabel, MenuItem, Select, FormControl, TextareaAutosize} from '@material-ui/core';

// Internal Modules
import './Song.scss';
import './fields';
import { F_BARS_LEAD_IN } from './fields';

// Define our actions...
const CHANGE_SONG_INFO = 'CHANGE_SONG_INFO';

// Compiled styles
const useStyles = makeStyles(theme => ({
    barsLeadIn: {
        width: 75,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(2),
    },
    textField: {
        width: 200,
    },
    artist: {
        width: 150,
    },
    beatsPerMeasure: {
        width: 125,
    },
    tempo: {
        width: 75,
    },
    formControl: {
        minWidth: 120,
    },
    songKey: {
        width: 100,
    },
    summary: {
        padding: theme.spacing(1),
        width: '100%',
    }
  }));

  // Our function component
function EditableSongInfo(props) {
    // Compiled classes...
    const classes = useStyles();

    // Our initial State...
    const initialState = {
        artistName_err: props.artistName_err,
        artistName: props.artistName,
        [F_BARS_LEAD_IN]: props.barsLeadIn || 0,
        beatsPerMeasure_err: props.beatsPerMeasure_err,
        beatsPerMeasure: props.beatsPerMeasure,
        eachBeatIsANote: props.eachBeatIsANote,
        songKey: props?.songKey,
        songTitle_err: props.songTitle_err,
        songTitle: props.songTitle,
        summary: props.summary,
        tempo_err: props.tempo_err,
        tempo: props.tempo,
    };

    // Our reducer
    const hdrReducer = (state, action) => {
        switch(action.type) {
            case CHANGE_SONG_INFO:
                // Get an object to hold error information...
                const errorObject = {};

                // Validating artist or song title?
                if (['artistName', 'songTitle'].includes(action.fieldName)) {
                    // Set the error field
                    errorObject[`${action.fieldName}_err`] = action.fieldValue.trim() === '' ? 'Empty!' : '';
                }

                // Validating beats per measure, tempo or bars lead in?
                if (['tempo', 'beatsPerMeasure', F_BARS_LEAD_IN].includes(action.fieldName)) {
                    // Convert to a number
                    const n = +action.fieldValue;

                    // Is this a field that can be zero?
                    if (action.fieldName === F_BARS_LEAD_IN) {
                        if (n < 0) {
                            errorObject[`${action.fieldName}_err`] = 'Must be >= 0';
                        } else {
                            // Clear the error property
                            errorObject[`${action.fieldName}_err`] = '';

                            // Convert the value to a number
                            action.fieldValue = n;
                        }
                    } else {
                        // Is the value 0?
                        if (n === 0) {
                            errorObject[`${action.fieldName}_err`] = 'Must be > 0';
                        } else {
                            // Clear the error property
                            errorObject[`${action.fieldName}_err`] = '';

                            // Convert the value to a number
                            action.fieldValue = n;
                        }
                    }
                }

                // Compute the new song JSON
                const newJson = Object.assign({}, state, {[action.fieldName]: action.fieldValue}, errorObject);

                // Do we have any errors?
                const haveErrors = Object.entries(newJson).reduce((acc, entry) => {
                    // Get the name, value
                    const [fName, fValue] = entry;

                    return acc || (fName.endsWith('_err') && !!fValue);
                }, false);

                // Inform our caller...
                setTimeout(() => props.disableSongSave('header', haveErrors), 10);
                setTimeout(() => props.saveSongInfo(newJson), 10);

                return Object.assign({}, state, newJson);
            default:
                return state;
        }
    };

    // Our local state...
    const [hdrState, dispatchHdrState] = useReducer(hdrReducer, initialState);

    // Local handlers...
    const handleChange = name => event => {
        dispatchHdrState({type: CHANGE_SONG_INFO, fieldName: name, fieldValue: event.target.value});
    };

    return (
        <form className={classes.container} noValidate autoComplete="off">
            <TextField
                autoFocus
                required
                error={!!hdrState.songTitle_err}
                label="Song Title"
                className={classes.textField}
                value={hdrState.songTitle}
                onChange={handleChange('songTitle')}
                helperText={hdrState.songTitle_err}
            />
            <TextField
                required
                error={!!hdrState.artistName_err}
                label="Artist"
                className={classes.artist}
                value={hdrState.artistName}
                onChange={handleChange('artistName')}
                helperText={hdrState.artistName_err}
            />
            <TextField
                required
                error={!!hdrState.beatsPerMeasure_err}
                label="Beats/measure"
                className={classes.beatsPerMeasure}
                value={hdrState.beatsPerMeasure}
                type="number"
                onChange={handleChange('beatsPerMeasure')}
                helperText={hdrState.beatsPerMeasure_err}
            />
            <FormControl className={classes.formControl}>
                <InputLabel htmlFor="ebian-simple">One Beat Is a...</InputLabel>
                <Select
                    value={hdrState.eachBeatIsANote}
                    onChange={handleChange('eachBeatIsANote')}
                    inputProps={{
                        name: 'eachBeatIsANote',
                        id: 'ebian-simple',
                    }}
                >
                    <MenuItem value={4}>Quarter Note</MenuItem>
                    <MenuItem value={8}>Eighth Note</MenuItem>
                    <MenuItem value={16}>Sixteenth Note</MenuItem>
                </Select>
            </FormControl>
            <TextField
                required
                error={!!hdrState.tempo_err}
                label="Tempo"
                className={classes.tempo}
                value={hdrState.tempo}
                type="number"
                onChange={handleChange('tempo')}
                helperText={hdrState.tempo_err}
            />
            <TextField
                label="Song Key"
                className={classes.songKey}
                value={hdrState?.songKey || ""}
                type="text"
                onChange={handleChange('songKey')}
            />
            <TextField
                error={!!hdrState.barsLeadIn_err}
                className={classes.barsLeadIn}
                label="Lead In"
                helperText={hdrState.barsLeadIn_err || "Click Bars"}
                value={hdrState?.[F_BARS_LEAD_IN] || 0}
                type="number"
                onChange={handleChange(F_BARS_LEAD_IN)}
            />
            <br/>
            <TextareaAutosize
                minRows={1}
                maxRows={3}
                label="Summary"
                className={classes.summary}
                value={hdrState.summary}
                onChange={handleChange('summary')}
                placeholder="Use this section to write down an overall summary or notes for the song."
            />
        </form>
    );
}

// Our Properties
EditableSongInfo.propTypes = {
  artistName: PropTypes.string.isRequired,
  artistName_err: PropTypes.string,
  beatsPerMeasure: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  beatsPerMeasure_err: PropTypes.string,
  disableSongSave: PropTypes.func.isRequired,
  eachBeatIsANote: PropTypes.number.isRequired,
  saveSongInfo: PropTypes.func.isRequired,
  songTitle: PropTypes.string.isRequired,
  songTitle_err: PropTypes.string,
  summary: PropTypes.string,
  tempo: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  tempo_err: PropTypes.string,
};

export default EditableSongInfo;