import React, { useState } from 'react';
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {Button, Dialog, AppBar, Toolbar, IconButton, Typography, Table, TableBody, TableRow, TableCell, Chip, Box, TextField} from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import DownArrowIcon from '@material-ui/icons/ArrowDropDownCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import AddCircleIcon from '@material-ui/icons/AddCircle';

// Internal modules...
import SlidedownTray from './SlidedownTray';
import {AT_OWN, AT_EDIT, AT_READ, AT_NONE, accessRoleMap, accessRoleDescMap, accessDescMap, accessTypeToNum} from './accessType';

// Define our CSS styles
const useStyles = makeStyles(theme => ({
    appBar: {
        paddingRight: '0!important',
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    p1: {
        marginTop: theme.spacing(1), /* TODO Figure out why top and bottom margins are not collapsing! */
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        '& ul': {
            marginLeft: theme.spacing(2)
        },
    },
    accessTable: {
        margin: theme.spacing(2),
        width: 'fit-content',
        '& td:nth-child(1)': {
            fontWeight: 'bold',
            maxWidth: '6em',
        },
        '& td:nth-child(2)': {
            fontStyle: 'italic',
            maxWidth: '15em',
        },
        '& tr:last-child td': {
            border: 'none',
        },
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    gridAccessDesc: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    diffTable: {
        width: 'fit-content',
        '& tr:last-child td': {
            border: 'none',
        },
    },
    form: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 0,
        marginRight: 0,
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SharingDialog(props) {
    // Construct our CSS classes...
    const classes = useStyles();

    // Construct our access map
    const accessMap = (props.sharingSettings && props.sharingSettings.length && props.sharingSettings.reduce((hsh, si) => {
        // Ensure that we have an array
        hsh[si.accessType] = hsh[si.accessType] || [];

        // Add the email to the array...
        hsh[si.accessType].push(si.email);

        return hsh;
    }, {})) || {};

    // What is the access granted to this user?
    const arrUserAccess = props.sharingSettingsOrig && props.sharingSettingsOrig.length && props.sharingSettingsOrig.filter(si => si.email === props.userId),
        userAccess = arrUserAccess && arrUserAccess.length === 1 && arrUserAccess[0].accessType;

    const handleClose = save => {
        if (!save) {
            props.onClose();
        }
        else {
            // Inform our caller...
            props.onSave(props.chartId, props.songTitle, props.sharingSettingsDiff);
        }
    };

    // Construct our table rows...
    const deleteIcon = useDown => useDown ? <DownArrowIcon /> : <CancelIcon />,
        chips = (emails, deleteFn, at) => emails && emails.map(e =>
            <Chip key={e}
                color="primary"
                variant="outlined"
                label={e}
                className={classes.chip}
                onDelete={deleteFn ? deleteFn.bind(null, e) : null}
                deleteIcon={deleteIcon(at !== AT_READ)}
                disabled={!deleteFn} />
            ),
        accessRole = at => <Box component="span">{accessRoleMap[at]}</Box>,
        accessRoleDesc = at => <Box component="span">{accessDescMap[at]}</Box>,
        hasAccess = at => accessTypeToNum[userAccess] >= accessTypeToNum[at],
        arrOwners = props.sharingSettings && props.sharingSettings.filter(si => si.accessType === AT_OWN),
        singleOwner = arrOwners && arrOwners.length === 1,
        deleteFn = at => hasAccess(at) && !(singleOwner && at === AT_OWN) ? props.onReduceUser : null,
        accessTableRows = [AT_OWN, AT_EDIT, AT_READ].map(at => 
            <TableRow key={at}>
                <TableCell>{accessRole(at)}</TableCell>
                <TableCell className={classes.gridAccessDesc}>{accessRoleDesc(at)}</TableCell>
                <TableCell>{chips(accessMap[at], deleteFn(at), at)}</TableCell>
            </TableRow>),
        accessTable = <Table className={classes.accessTable} size="small">
                    <TableBody>
                        {accessTableRows}
                    </TableBody>
                </Table>;
    
    // Do we have any changes?
    const differences = Object.keys(props.sharingSettingsDiff || {}).length > 0,
        atChangeDesc = (at, email) => {
            const wasArr = props.sharingSettingsOrig.filter(si => si.email === email),
                was = wasArr.length === 1 && wasArr[0].accessType,
                wasDesc = wasArr.length === 1 ? ` (originally: ${accessRoleMap[was]})` : '';
            
            if (at.now === AT_NONE) {
                return `Removed${wasDesc}`;
            }
            else if (!was) {
                return `Added as ${accessRoleMap[at.now]}`
            }

            return `Changed to ${accessRoleMap[at.now]}${wasDesc}`;
        },
        unusedChips = differences && Object.entries(props.sharingSettingsDiff).reduce((arr, [e, atObj]) => {
            if (atObj && atObj.now === AT_NONE) {
                arr.push(<Chip key={`unused::${e}`}
                    color="primary"
                    variant="outlined"
                    label={e}
                    className={classes.chip}
                    onDelete={props.onAddUser.bind(null, e, userAccess)}
                    deleteIcon={<AddCircleIcon />}
                    />
                );
            }
            return arr;
        }, []);

    // State for the input
    const [email, setEmail] = useState('');
    const [email_err, setEmailErr] = useState('');

    // Handling change from inputs
    const handleEmailChange = evt => {
        const newEmail = evt.target.value.trim();

        // Is the email already in the list?
        const arrMatch = props.sharingSettings.filter(si => si.email === newEmail);
        if (arrMatch.length > 0 && arrMatch[0].accessType === userAccess) {
            setEmailErr(`already listed`);
        } else {
            setEmailErr('');
        }

        setEmail(newEmail);
    };
    const onAddUser = () => {
        // Remove the email
        const emailToAdd = email;
        setEmail('');

        // Inform our caller...
        props.onAddUser(emailToAdd, userAccess);
    };
    const resetDisabled = !differences || props.sdtInProgress;

    return (
        <Dialog fullScreen open={props.show} onClose={handleClose.bind(this, false)} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose.bind(this, false)} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>Sharing</Typography>
                    <Button autoFocus color="inherit" onClick={props.onReset} disabled={resetDisabled}>reset</Button>
                    <Button autoFocus color="inherit" onClick={handleClose.bind(this, true)} disabled={resetDisabled}>save</Button>
                </Toolbar>
                <SlidedownTray
                    contentKey='saveSharing'
                    inProgress={props.sdtInProgress}
                    isSuccess={props.sdtIsSuccess}
                    message={props.sdtMessage}
                    show={props.sdtShow}
                />
            </AppBar>
            <Typography variant="body1" className={classes.p1}>
                This screen allows you to share this song with others. For this song, you are an <strong>{accessRoleMap[userAccess]}</strong>,
                {accessRoleDescMap[userAccess]}.
            </Typography>
            <Typography variant="body1" className={classes.p1}>
                <strong>Higher level access implies lower ones.</strong> The nature of each access type is that it <em>implicitly</em> grants
                lower accesses. For example, if User A is an <strong>EDITOR</strong>, she is also <em>implicitly</em> a <strong>READER</strong>.
                It would be hard to allow someone to edit a chart that they cannot see. Same goes for <strong>OWNER</strong> rights: you implicitly
                grant <strong>EDITOR</strong> and <strong>READER</strong> rights.
            </Typography>
            <Typography variant="body1" className={classes.p1}>
                <strong>Removing means Reducing Access.</strong> For each user can you remove from a role, doing so only <em>reduces</em> their access
                to the next lower level. Thus, if you try to remove User A which is an <strong>EDITOR</strong> in a chart, doing so reduces them
                to <strong>READER</strong> access. For some cases, that may be sufficient. If you want to remove User A entirely, you need to remove
                them from the <strong>READER</strong> section.
            </Typography>
            {accessTable}
            <form className={classes.form} noValidate autoComplete="off">
                <Box>
                <Box alignItems="baseline" display="flex">
                    <Box display="inline" pr={2}><strong>Share with a new user: </strong></Box>
                    <TextField
                        error={!!email_err}
                        label={`Email${email_err ? ` (${email_err})` : ''}`}
                        className={classes.textField}
                        onChange={handleEmailChange}
                        value={email}
                    >
                    </TextField>
                    <Button
                        variant="outlined"
                        disabled={email === '' || !!email_err}
                        onClick={onAddUser}
                    >
                        Add
                    </Button>
                    <Box display="inline" color="error.main" fontWeight="fontWeightLight" pl={2}>{props.addUserMessage}</Box>
                </Box>
                { unusedChips && unusedChips.length > 0 &&
                <Box pr={2}><strong>Re-add user:</strong> {unusedChips}</Box>
                }
                </Box>
            </form>
            {differences &&
            <>
                <Typography variant="body1" className={classes.p1}>
                    You are making the following changes:
                </Typography>
                <Table className={classes.diffTable}>
                    <TableBody>
                        {Object.entries(props.sharingSettingsDiff).map(([email, at]) => 
                            <TableRow key={email}>
                                <TableCell align="right">{email}:</TableCell>
                                <TableCell>{atChangeDesc(at, email)}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </>
            }
            {!differences && 
            <Typography variant="body1" className={classes.p1}>
                No changes.
            </Typography>
        }
        </Dialog>
    );
}
SharingDialog.propTypes = {
    addUserMessage: PropTypes.string,
    chartId: PropTypes.string,
    onAddUser: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onDeleteUser: PropTypes.func.isRequired,
    onReduceUser: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    sdtInProgress: PropTypes.bool,
    sdtIsSuccess: PropTypes.bool,
    sdtMessage: PropTypes.string,
    sdtShow: PropTypes.bool,
    sharingSettings: PropTypes.array,
    sharingSettingsDiff: PropTypes.object,
    sharingSettingsOrig: PropTypes.array,
    show: PropTypes.bool.isRequired,
    songTitle: PropTypes.string,
    userId: PropTypes.string,
}
