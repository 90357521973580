// Extermal Modules
import PropTypes from "prop-types";
import React from 'react';
import {connect} from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router-dom';

const ProtectedRoute = ({children, auth, ...rest}) => {
    return <Route {...rest} render={({location, match}) => {
        if (auth.isLoggedIn) {
            return React.cloneElement(children, {match});
        } else {
            return <Redirect to={{
                pathname: "/login",
                state: {
                    from: location
                }
            }} />
        }
    }} />;
};

ProtectedRoute.propTypes = {
    auth: PropTypes.shape({
        isLoggedIn: PropTypes.bool,
    }),
};

const mapStateToProps = state => {
    return {
        auth: state.login,
    };
}

export default withRouter(connect(mapStateToProps)(ProtectedRoute));