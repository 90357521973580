// External modules
import React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware, compose} from 'redux';
import createSagaMiddleware from 'redux-saga';

// Local modules
import AppContainer from './AppContainer';
import rootReducer from './reducers/index';
// import * as serviceWorker from './serviceWorker';
import rootSaga from './sagas';

// Create our Saga middleware
const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })) || compose;

// Create our Redux Store
const store = createStore(rootReducer, undefined, composeEnhancers(applyMiddleware(sagaMiddleware)));

// Run our middleware?
sagaMiddleware.run(rootSaga);

render(
    <Provider store={store}>
        <AppContainer />
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
