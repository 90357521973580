// External module: React
import React from 'react';
import PropTypes from 'prop-types';

// External Module: MUI Core
import {makeStyles} from '@material-ui/core/styles';
import {Typography, Paper} from '@material-ui/core';

// External Module: React Router
import {useLocation, Redirect} from 'react-router-dom';

// Internal modules
import LoadingMessage from './LoadingMessage';
import GoogleAuth from './auth/GoogleAuth';
import {useMount} from './util';

// TODO
// 1. Make auth state (initializing, error) to be local state?

// Construct our styles...
const useStyles = makeStyles(theme => ({
    paper: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
    },
    container: {
        margin: 'auto',
        marginTop: theme.spacing(10),
        width: '400px',
        border: '3px double #999',
        padding: theme.spacing(2),
    },
    googleSignInImage: {
        display: 'block',
        cursor: 'pointer',
        margin: 'auto',
        marginTop: '16px',
    },
    loadingDiv: {
        padding: theme.spacing(1)
    },
    progress: {
        margin: theme.spacing(1)
    },
    loading: {
        bottom: theme.spacing(1),
        position: 'relative'
    },
}));

export default function LoginPage(props) {
    // Use our compiled CSS classes
    const classes = useStyles();

    // On startup, check our existing session...
    useMount(() => {
        // Have we not yet tried to check for a connection?
        if (props.isLoggedIn === undefined) {
            props.checkSession();
        }
    });

    // Get our location object...
    const location = useLocation();

    // Get where we were coming FROM so that we can go back TO it when we are done
    const { from } = location.state || { from: { pathname: "/home" } };

    return(
        <div>
            { props.getSessionStarted &&
                <LoadingMessage text='Checking for an existing session...' />
            }
            { props.loggingIn &&
                <LoadingMessage text='Logging you in...' />
            }
            { props.isLoggedIn &&
                <Redirect to={from} />
            }
            { props.isLoggedIn === false && props.loginError &&
                <Paper className={classes.paper}>
                    <Typography variant="body1" paragraph>
                        <strong>Login Failure.</strong> There is an internal error with the system. Cannot log you in at this time: <code>{props.loginError}</code>
                    </Typography>
                </Paper>
            }
            { !props.loggingIn && props.isLoggedIn === false &&
                <div className={classes.container}>
                    <Typography sx={{ mb: 2 }}>
                        At this time, we only support logging in with a Google account. If you want to be notified when standard accounts
                        are available, send an email to <a href="mailto:help@mybandcharts.com?subject=Tell+me+when+standard+accounts+are+available" target="_blank" rel="noopener noreferrer">help@mybandcharts.com</a>.
                    </Typography>
                    { props.authInitializing === false && !props.authInitError &&
                        <div className={classes.googleSignInImage}>
                            <GoogleAuth
                                onIsLoggedOn={props.onIsLoggedOn}
                                onLoginFailed={props.onLoginFailed}
                                onLoginStarted={props.onLoginStarted}
                                onLoginSuccess={props.onLoginSuccess}
                                onLogoutFailed={props.onLogoutFailed}
                                onLogoutStarted={props.onLogoutStarted}
                                onLogoutSuccess={props.onLogoutSuccess}
                            />
                        </div>
                    }
                </div>
            }
        </div>
    );
}

LoginPage.propTypes = {
    authInitError: PropTypes.string,
    authInitializing: PropTypes.bool,
    checkSession: PropTypes.func.isRequired,
    getSessionStarted: PropTypes.bool,
    handleLogin: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool,
    loadingMsg: PropTypes.string,
    loggingIn: PropTypes.bool,
    loginError: PropTypes.string,
    onAuthInitFailed: PropTypes.func.isRequired,
    onAuthInitStarted: PropTypes.func.isRequired,
    onAuthInitSuccess: PropTypes.func.isRequired,
    onCheckIsLoggedOn: PropTypes.func.isRequired,
    onIsLoggedOn: PropTypes.func.isRequired,
    onLoginFailed: PropTypes.func.isRequired,
    onLoginStarted: PropTypes.func.isRequired,
    onLoginSuccess: PropTypes.func.isRequired,
    onLogoutFailed: PropTypes.func.isRequired,
    onLogoutStarted: PropTypes.func.isRequired,
    onLogoutSuccess: PropTypes.func.isRequired,
    showLoading: PropTypes.bool.isRequired,
};
