// External modules
import PropTypes from "prop-types";
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Typography, Paper, Button} from '@material-ui/core';
import {Link as RouterLink, Redirect} from 'react-router-dom';

// Internal modules
import {useMount} from './util';

// Get our checkbox image
import checkbox from './checkbox.png';

// Construct our styles...
const useStyles = makeStyles(theme => ({
    paper: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
    },
    subjectPage: {
        backgroundColor: '#ccc',
        padding: theme.spacing(2),
    },
    sectionHeader: {
        margin: theme.spacing(2),
        marginLeft: theme.spacing(0),
    },
    goodChartCntr: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        paddingTop: theme.spacing(2),
    },
    panel: {
        width: '550px',
        padding: theme.spacing(2),
        backgroundColor: '#999',
        marginRight: theme.spacing(2),
    },
    singleDesc: {
        marginTop: theme.spacing(2),
        '&:nth-of-type(1)': {
            marginTop: theme.spacing(0),
        }
    },
    pullOut: {
        display: 'inline-block',
        border: '3px solid #28b216',
        borderRadius: '50%',
        backgroundColor: '#a5f39d',
        textAlign: 'center',
        width: '20px',
        height: '20px',
        fontWeight: 700,
        fontSize: '0.9rem',
    },
  }));

  export default function LandingPage(props) {
    // Use our compiled CSS classes
    const classes = useStyles();

    // On startup, check our existing session...
    useMount(props.checkSession);

    return (
        <>
            { props.isLoggedIn &&
                <Redirect to="/home" />
            }
            { !props.isLoggedIn &&
                <Paper className={classes.paper}>
                    <div className="container">
                        <div className="image-and-text">
                            <div className="block block-drums">
                            <p className="overlay-text">ARE YOU<br/>IN A BAND?</p>
                            </div>
                            <p className="descriptive-text">I am. <em>Several.</em> Fill-in drummer for other bands as well. Looking for something to do? It's not a good time to play out.</p>
                        </div>
                        <div className="image-and-text">
                            <div className="block block-singer-plus-bass">
                            <p className="overlay-text">HOW DO YOU<br/>SHARE SONG<br/>STRUCTURE?</p>
                            </div>
                            <p className="descriptive-text">Whiteboards? Napkins? Oral tradition? Do you know the tempo? Measure counts for each section? How do you train new members?</p>
                        </div>
                        <div className="image-and-text">
                            <div className="block block-band-members-on-stage">
                            <p className="overlay-text">ARE YOU TRYING<br/>TO RECORD<br/>YOUR SONGS?</p>
                            </div>
                            <p className="descriptive-text">Whether via file share or recording studio, how do you get your engineer up to speed? Is it easy to punch in anywhere in the song?</p>
                        </div>
                        <div className="image-and-text">
                            <div className="block block-audience-band-blur">
                            <p className="overlay-text">LOOKING FOR A<br/>FREE SITE<br/>TO ASSIST YOU?</p>
                            </div>
                            <p className="descriptive-text"><em>Now we're talking.</em> Can everyone in my band collaborate? <img alt="" className="check" src={checkbox} />
                                Can I print out my charts? <img alt="" className="check" src={checkbox} />
                                Do I keep exclusive access of my content? <img alt="" className="check" src={checkbox} /> And it's free? <img alt="" className="check" src={checkbox} />
                            </p>
                        </div>
                    </div>
                    <div className={classes.subjectPage}>
                        <Typography variant="h4" className={classes.sectionHeader}>Elements of a Good Chart</Typography>
                        <Typography variant="body1">This is an example of a chart in My Band Charts.</Typography>
                        <div className={classes.goodChartCntr}>
                            <div className={classes.panel}>
                                <Typography className={classes.singleDesc}>
                                    <span className={classes.pullOut}>1</span> - <strong>The Song Title and the Artist's Name.</strong> The most obvious parts of the chart.
                                </Typography>
                                <Typography className={classes.singleDesc}>
                                    <span className={classes.pullOut}>2</span> - <strong>Song Version.</strong> Keep track of how often your song is changing. Each time you save,
                                    we increment the version count.
                                </Typography>
                                <Typography className={classes.singleDesc}>
                                    <span className={classes.pullOut}>3</span> - <strong>Dates.</strong> Show when your chart was created and last modified (and by whom). <em>This section
                                    can be hidden, if you are not interested.</em>
                                </Typography>
                                <Typography className={classes.singleDesc}>
                                    <span className={classes.pullOut}>4</span> - <strong>Time and Recording Info.</strong> What is the time signature? Tempo? Duration of the song? These are
                                    shown along with the number of bars, how long a beat or bar is. <em>This section can also be hidden, if you are not interested.</em>
                                </Typography>
                                <Typography className={classes.singleDesc}>
                                    <span className={classes.pullOut}>5</span> - <strong>Summary.</strong> If you have notes that apply to the entire song, you can enter them here.
                                    You can also leave it blank.
                                </Typography>
                                <Typography className={classes.singleDesc}>
                                    <span className={classes.pullOut}>6</span> - <strong>Section Name.</strong> The more important part of each section: what is it in relation to the rest of the
                                    song. Use any names you like. Use the same name consecutively to show "sub-parts" of a section.
                                </Typography>
                                <Typography className={classes.singleDesc}>
                                    <span className={classes.pullOut}>7</span> - <strong>Section Duration.</strong> In terms of the number of bars.
                                </Typography>
                                <Typography className={classes.singleDesc}>
                                    <span className={classes.pullOut}>8</span> - <strong>Section Notes.</strong> Include notes about what the entire band is doing in this section.
                                </Typography>
                                <Typography className={classes.singleDesc}>
                                    <span className={classes.pullOut}>9</span> - <strong>Instrument Notes.</strong> There are different sections for drums, guitar and bass. Include instruction
                                    like what feel of beat is being played, chord progression or notes played. <em>You can omit any of these sections.</em>
                                </Typography>
                                <Typography className={classes.singleDesc}>
                                    <span className={classes.pullOut}>10</span> - <strong>Measure Counter.</strong> Helpful for recording (locating "punch ins"), this shows you what measures each
                                    section occupies (beginning measure and ending measure).
                                </Typography>
                            </div>
                            <img className="sample-chart" alt="" />
                        </div>
                    </div>
                    <div style={{margin: '2em'}}>
                        <Typography variant="h5" style={{fontWeight: 'normal'}} paragraph>
                            <strong>Welcome to My Band Charts.</strong> This is a site that makes it easy to create song charts. These charts give direction to band members
                            regarding song structure, specifics on each member's parts, measure counts and overall duration (based on a supplied tempo). These charts were
                            designed to make it easier during a performance or recording.
                        </Typography>
                        <Typography variant="h6" style={{fontWeight: 'normal'}} paragraph>
                            To use this site, you'll need a Google email. Sorry, we only support Google Sign-In at this time. Later, we will allow
                            users to create their own accounts (regardless of existing provider). Right now, it's easier as you won't have to
                            remember a new user name and password.
                        </Typography>
                        <Typography variant="h6" style={{fontWeight: 'normal'}} paragraph>
                            Once you login, you will find a number of song charts associated with your account. If you have none, you can create ones.
                            You may view, edit, share and print any of these charts.
                        </Typography>
                        <Button variant="contained" color="primary" component={RouterLink} to="/login">Login</Button>
                    </div>
                </Paper>
            }
        </>
    );
}
LandingPage.propTypes = {
    checkSession: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool,
}
