// External modules
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {Typography, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import moment from 'moment';

// Internal modules...
import LoadingMessage from './LoadingMessage';
import songDuration from './songDuration';
import {accessRoleMap} from './accessType';
import {useMount, constructSongPath} from './util';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  container: {
    height: 'calc(100vh - 64px)'
  },
  table: {
    minWidth: 650,
  },
  row: {
    cursor: 'pointer'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

// Song Info Fields...
const FLD_SONG_TITLE = 'songTitle';
const FLD_ARTIST_NAME = 'artistName';
const FLD_READERS = 'readers';
const FLD_TEMPO = 'tempo';
const FLD_VERSION = 'version';
const FLD_CREATION_DATE = 'creationDate';
const FLD_CREATION_USER = 'creationUser';
const FLD_LAST_MOD_DATE = 'lastModificationDate';
const FLD_LAST_MOD_USER = 'lastModificationUser';
const FLD_BEATS_PER_MEASURE = 'beatsPerMeasure';
const FLD_EACH_NOTE_IS = 'eachBeatIsANote';
const FLD_ACCESS_GRANTED = 'acg';

// Derived Fields...
const DFLD_LAST_UPDATE_DATE = 'lastUpdateDate';
const DFLD_LAST_UPDATE_USER = 'lastUpdateUser';
const DFLD_TIME_SIGNATURE = 'timeSig';
const DFLD_DURATION = 'duration';

// The titles of the columns
const hshColumnTitle = {
  [FLD_SONG_TITLE]: 'Song Title',
  [FLD_ARTIST_NAME]: 'Artist',
  [FLD_READERS]: 'Accessible To',
  [FLD_TEMPO]: 'Tempo',
  [FLD_VERSION]: 'Version',
  [FLD_ACCESS_GRANTED]: 'Access',
  [DFLD_LAST_UPDATE_DATE]: 'Last Updated',
  [DFLD_LAST_UPDATE_USER]: 'Last Updater',
  [DFLD_TIME_SIGNATURE]: 'Time Signature',
  [DFLD_DURATION]: 'Duration',
};

// The alignment of the cells
const hshAlign = {
  [FLD_SONG_TITLE]: 'left',
  [FLD_ARTIST_NAME]: 'left',
  [FLD_READERS]: 'left',
  [FLD_TEMPO]: 'right',
  [FLD_VERSION]: 'right',
  [FLD_ACCESS_GRANTED]: 'left',
  [DFLD_LAST_UPDATE_DATE]: 'left',
  [DFLD_LAST_UPDATE_USER]: 'left',
  [DFLD_TIME_SIGNATURE]: 'left',
  [DFLD_DURATION]: 'right',
}

const tcScope = fieldName => {
  if (fieldName === FLD_SONG_TITLE) {
    return 'row';
  }
  return null;
}

const derivedFields = songInfo => {
  return {
    [DFLD_LAST_UPDATE_DATE]: songInfo[FLD_LAST_MOD_DATE] || songInfo[FLD_CREATION_DATE],
    [DFLD_LAST_UPDATE_USER]: songInfo[FLD_LAST_MOD_DATE] ? songInfo[FLD_LAST_MOD_USER] : songInfo[FLD_CREATION_USER],
    [DFLD_TIME_SIGNATURE]: songInfo[FLD_BEATS_PER_MEASURE]*10 + songInfo[FLD_EACH_NOTE_IS],
    [DFLD_DURATION]: songDuration(songInfo).sec,
  };
};

// Our column fields...
const arrColumnFields = [FLD_SONG_TITLE, FLD_ARTIST_NAME, FLD_VERSION, DFLD_DURATION, FLD_ACCESS_GRANTED, FLD_TEMPO, DFLD_TIME_SIGNATURE, DFLD_LAST_UPDATE_DATE, DFLD_LAST_UPDATE_USER];

function SongList(props) {
  // Construct our class names...
  const classes = useStyles();

  // Get all charts, if they are not already loaded...
  useMount(() => {
    // Do we have any charts?
    // TODO Consider how in a future version we might invoke getCharts again after some amount of delay (say 1hr)
    // and how we might show NEW charts that appear
    if (props.requestingCharts === undefined) {
      props.getCharts();
    }
  });

  // Add in sortable fields for those that are derived...
  const rows = (props.songInfos || []).map(si => Object.assign({}, si, {...derivedFields(si)}));

  // Who is the song accessible to?
  const accessibleTo = arrReaders => {
    // Verify that we have a readers array...
    if (!arrReaders || !arrReaders.length) {
      return 'No one! (Internal error)';
    }

    // Just you?
    if (arrReaders.length === 1 && arrReaders[0] === props.basicProfile.email) {
      return 'Only you';
    }

    const everyoneElse = arrReaders.filter(e => e !== props.basicProfile.email);
    return ['You'].concat(everyoneElse).join(', ');
  };

  const transformField = (fieldName, hshSongInfo) => {
    const fieldValue = hshSongInfo[fieldName];

    // readers is an array...
    if (fieldName === FLD_READERS) {
      return accessibleTo(fieldValue);
    }

    // time signature is a grouping of two fields...
    if (fieldName === DFLD_TIME_SIGNATURE) {
      return `${hshSongInfo[FLD_BEATS_PER_MEASURE]}/${hshSongInfo[FLD_EACH_NOTE_IS]}`;
    }

    // Last Updated Date
    if (fieldName === DFLD_LAST_UPDATE_DATE) {
      return moment.unix(hshSongInfo[FLD_LAST_MOD_DATE] || hshSongInfo[FLD_CREATION_DATE]).fromNow();
    }

    // Last Updated User
    if (fieldName === DFLD_LAST_UPDATE_USER) {
      if (hshSongInfo[FLD_LAST_MOD_DATE]) {
        return hshSongInfo[FLD_LAST_MOD_USER];
      }
      return hshSongInfo[FLD_CREATION_USER];
    }

    // Duration
    if (fieldName === DFLD_DURATION) {
      return songDuration(hshSongInfo).str;
    }

    // Access Granted
    if (fieldName === FLD_ACCESS_GRANTED) {
      return accessRoleMap[fieldValue];
    }

    return fieldValue;
  };

  const tcComponent = fieldName => {
    if (fieldName === FLD_SONG_TITLE) {
      return 'th';
    }
    return null;
  };

  const handleRequestSort = (event, fieldName) => {
    const isAsc = props.sortField === fieldName && props.sortOrder === 'asc';
    props.onChangeSort(fieldName, isAsc ? 'desc': 'asc');
  };

  const createSortHandler = fieldName => event => {
    handleRequestSort(event, fieldName);
  };

  function descendingComparator(a, b, orderBy) {
    const isString = typeof a[orderBy] === 'string',
      aFieldValue = isString ? a[orderBy].toLowerCase() : a[orderBy],
      bFieldValue = isString ? b[orderBy].toLowerCase() : b[orderBy];

    if (bFieldValue < aFieldValue) {
      return -1;
    }
    if (bFieldValue > aFieldValue) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  // Construct the table cells that form our header
  const arrHeaderTableCells = arrColumnFields.map(f =>
    <TableCell
      key={f}
      sortDirection={props.sortField === f ? props.sortOrder : false}
      align={hshAlign[f]}
      component="th"
    >
      <TableSortLabel
        active={props.sortField === f}
        direction={props.sortField === f ? props.sortOrder : 'asc'}
        onClick={createSortHandler(f)}
      >
      {hshColumnTitle[f]}
      {props.sortField === f ? (
        <span className={classes.visuallyHidden}>
          {props.sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
        </span>
      ) : null}
      </TableSortLabel>
    </TableCell>);

  // Construct the table cells that form our body
  const arrBodyTableCells = row => arrColumnFields.map(f =>
    <TableCell
      key={f}
      component={tcComponent(f)}
      align={hshAlign[f]}
      scope={tcScope(f)}
    >
      {transformField(f, row)}
    </TableCell>);

  // Get our History object...
  const history = useHistory();

  // What to do when we click on a row
  const onRowClick = row => {
    // Perform our action of selecting the song
    props.onSelectSong(row.id, row.songTitle, row.lastLoaded);

    // Compute a URL friendly song name
    const songPath = constructSongPath(row.songTitle, row.id);

    // Change our route...
    history.push(songPath);
  };

  // Construct the table row elements that form our body
  const arrBodyTableRows = stableSort(rows, getComparator(props.sortOrder, props.sortField)).map((row, ix) =>
    <TableRow className={classes.row} key={ix} onClick={onRowClick.bind(null, row)} hover>
      {arrBodyTableCells(row)}
    </TableRow>
  );

  // How do we greet the user?
  const greeting = (() => {
    const bp = props.basicProfile;

    return `Welcome ${bp ? (bp.givenName || bp.fullName || bp.email) : 'User'}!`;
  })();

  return (
    <>
      { props.requestingCharts &&
        <LoadingMessage
          text='Retrieving your charts...'
        />
      }
      { props.requestingCharts === false && props.getChartsError &&
        <Typography variant="body1" paragraph>
            <strong>Unable to get your charts.</strong> There was an error trying to retrieve your charts: <code>{props.getChartsError}</code>
        </Typography>
      }
      { props.requestingCharts === false && props.songInfos && props.songInfos.length === 0 &&
        <Typography variant="body1" paragraph>
          <strong>{greeting}</strong> You do not currently have any charts shared with you. Feel
          free to create one now!
        </Typography>
      }
      { props.requestingCharts === false && props.songInfos && props.songInfos.length > 0 &&
        <TableContainer className={classes.container}>
          <Table stickyHeader className={classes.table}>
            <TableHead>
              <TableRow>
                {arrHeaderTableCells}
              </TableRow>
            </TableHead>
            <TableBody>
              {arrBodyTableRows}
            </TableBody>
          </Table>
        </TableContainer>
      }
    </>
  );
}

SongList.propTypes = {
  basicProfile: PropTypes.shape({
    email: PropTypes.string.isRequired,
    fullName: PropTypes.string,
    givenName: PropTypes.string,
  }),
  getCharts: PropTypes.func.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  onSelectSong: PropTypes.func.isRequired,
  requestingCharts: PropTypes.bool,
  songInfos: PropTypes.array,
  sortField: PropTypes.string,
  sortOrder: PropTypes.string,
};

export default SongList;