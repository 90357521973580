import React from 'react';

// Access types
export const AT_OWN = 'OWN';
export const AT_EDIT = 'EDIT';
export const AT_READ = 'READ';
export const AT_NONE = 'NONE';

const ALL_TYPES = [AT_OWN, AT_EDIT, AT_READ, AT_NONE];

// Map an access type to a numeric value...
export const accessTypeToNum = {
    [AT_OWN]: 2,
    [AT_EDIT]: 1,
    [AT_READ]: 0,
    [AT_NONE]: -1,
};

// Whether one access type has MORE access (not equal) than another
export const hasMoreAccess = (at1, at2) => {
    return accessTypeToNum[at1] > accessTypeToNum[at2];
}

// A map of access type descriptions...
export const accessDescMap = {
    [AT_OWN]: 'Ability to delete a chart + EDIT + READ',
    [AT_EDIT]: 'Ability to edit a chart + READ',
    [AT_READ]: 'Ability to see and print a chart'
};

// A map of access type roles...
export const accessRoleMap = {
    [AT_OWN]: 'OWNER',
    [AT_EDIT]: 'EDITOR',
    [AT_READ]: 'READER',
}

// A map of access type role descriptions...
export const accessRoleDescMap = {
    [AT_OWN]: [' which means you can allow others to become ', <strong key={1}>OWNER</strong>, 's (which grants them the ability to ' +
            'delete this chart). You can also grant others the ability to be ', <strong key={2}>EDITOR</strong>, 's and ',
            <strong key={3}>READER</strong>, 's'],
    [AT_EDIT]: [' which means you can allow others to become ', <strong key={4}>EDITOR</strong>, 's (which grants them the ability to ' +
            'edit this chart). You can also grant others the ability to simply view the chart, which makes them ',
            <strong key={5}>READER</strong>, 's'],
    [AT_READ]: [' which means you can allow owthers to become ', <strong key={6}>READER</strong>, 's (which grants them the ability to ' +
            'view this chart)']
}

// Reduce an access right...
export const reduceAccessRight = at => {
    if (at === AT_OWN) {
        return AT_EDIT;
    }
    if (at === AT_EDIT) {
        return AT_READ;
    }
    if (at === AT_READ) {
        return AT_NONE;
    }
    return at;
}

// Check whether the supplied string is a valid access right...
export const isValidAccessType = str => {
    return ALL_TYPES.includes(str);
}
