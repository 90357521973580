// External Module: React
import React, { useReducer } from 'react';
import PropTypes from 'prop-types';

// External Module: clsx
import clsx from 'clsx';

// External Module: MUI Core
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';

// External Module: MUI Icons
import GetAppIcon from '@material-ui/icons/GetApp';
import PrintIcon from '@material-ui/icons/Print';

// External Module: lodash
import { get } from 'lodash';

// External Module: MUI Styles
import { makeStyles } from '@material-ui/core/styles';

// External Module: React Router
import { useHistory } from 'react-router-dom';

// Internal Modules
import './Song.scss';
import ReadOnlySongInfo from './ReadOnlySongInfo';
import EditableSongInfo from './EditableSongInfo';
import ReadOnlyChartSection from './ReadOnlyChartSection';
import EditableChartSection from './EditableChartSection';
import { constructSongPath, useMount } from './util';
import { F_BARS_LEAD_IN } from './fields';

// Compiled styles
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#eee',
    },
    formControl: {
        padding: theme.spacing(2),
        margin: 0,
    },
}));

// Constants
const SHOW_CHART_SECTION = 'SHOW_CHART_SECTION';
const SHOW_SONG_SECTION = 'SHOW_SONG_SECTION';

// Predefined Chart Sections
const PREDEFINED_CHART_SECTIONS = [
    {
        label: 'NOTES',
        field: 'note',
    },
    {
        label: 'BASS',
        field: 'bass',
    },
    {
        label: 'DRUMS',
        field: 'drums',
    },
    {
        label: 'GUITAR',
        field: 'guitar',
    },
    {
        label: 'CHORDS',
        field: 'chords',
    },
    {
        label: 'LYRICS',
        field: 'lyrics',
    },
    {
        label: 'KEYS/PIANO',
        field: 'keyspiano',
    },
];

function Song(props) {
    // Compile our CSS styles...
    const classes = useStyles();

    // Our history object
    const history = useHistory();

    // Whether there are any values for a section in a chart
    const hasValues = field =>
        (props.selectedSong ? props.selectedSong.chartInfo : []).reduce(
            (bln, ci) => {
                return bln || !!ci[field];
            },
            false
        );

    // The sections of the chart and the corresponding field of the "chartInfo"
    // TODO This initial state ASSUMES that the selectedSong is already LOADED. If the user is
    // loading this page via a Route, then this is NOT the case. In which case, no chart sections
    // are shown. NEED TO CONSIDER HOW TO FIX THIS CLEANLY (e.g., moving this state into Redux)
    const sectionInitialState = {
        songSections: [
            { title: 'DATES', field: 'dates', show: true },
            { title: 'REC INFO', field: 'recinfo', show: false },
            { title: 'TIME INFO', field: 'timeinfo', show: true },
        ],
        chartSections: [
            {
                title: 'SECTION',
                field: 'songForm',
                show: true,
                allowShowHide: false,
            },
            { title: 'BARS', field: 'bars', show: true, allowShowHide: false },

            ...PREDEFINED_CHART_SECTIONS.map(({ label, field }) => ({
                title: label,
                field,
                show: hasValues(field),
                allowShowHide: true,
            })),
        ],
    };

    // Do we have an actual (persisted) song ID?
    const persistedSongId = get(props, 'selectedSong.id'),
        persistedSongTitle = get(props, 'selectedSong.songTitle');
    if (persistedSongId) {
        // Compute what the actual path should be for this song
        const correctPath = constructSongPath(
            persistedSongTitle,
            persistedSongId
        );

        // Does our desired path NOT match what we are showing?
        if (history.location.pathname !== correctPath) {
            setTimeout(() => {
                history.replace(correctPath);
            }, 0);
        }
    }

    // Use the React Router match prop to find the referenced Song ID and friendly name
    // portions of this URL. Decode the friendly name to be even "friendlier" for showing
    // to the user.
    const songIdPath = 'match.params.songId',
        friendlyNamePath = 'match.params.friendlyName',
        songId = get(props, songIdPath),
        friendlyName = get(props, friendlyNamePath, '').replace(/_/g, ' '),
        { onSelectSong, selectedSong } = props;

    // old dependency array: [songId, onSelectSong, friendlyName, selectedSong]
    useMount(() => {
        // Scroll to the top during did mount
        window.scrollTo(0, 0);

        // Were we invoked without a selectedSong? This is the case when we
        // are rendering the page from a bookmarked URL for a Song
        if (!selectedSong) {
            // Do we have a Song ID from the URL?
            if (!songId) {
                alert(`Cannot get songIdPath=${songIdPath} from props`);
            } else {
                // Retrieve the song
                onSelectSong(songId, friendlyName);
            }
        }
    });

    // What sections to show, hide
    const sectionReducer = (state, action) => {
        switch (action.type) {
            case SHOW_SONG_SECTION:
                return Object.assign({}, state, {
                    songSections: state.songSections.map(cs => {
                        if (cs.field === action.fieldName) {
                            return Object.assign({}, cs, {
                                show: action.checked,
                            });
                        } else {
                            return cs;
                        }
                    }),
                });
            case SHOW_CHART_SECTION:
                return Object.assign({}, state, {
                    chartSections: state.chartSections.map(cs => {
                        if (cs.field === action.fieldName) {
                            return Object.assign({}, cs, {
                                show: action.checked,
                            });
                        } else {
                            return cs;
                        }
                    }),
                });
            default:
                return state;
        }
    };

    // Hold local state for what sections to show
    const [sectionState, sectionDispatch] = useReducer(
        sectionReducer,
        sectionInitialState
    );

    // Action Handlers
    const handleSongSectionChange = evt =>
        sectionDispatch({
            type: SHOW_SONG_SECTION,
            fieldName: evt.target.name,
            checked: evt.target.checked,
        });
    const handleChartSectionChange = evt =>
        sectionDispatch({
            type: SHOW_CHART_SECTION,
            fieldName: evt.target.name,
            checked: evt.target.checked,
        });

    // Handler if a user selects a breadcrumb
    const handleFolderSelect = (path, event) => {
        // Prevent the default handling of the event
        event.preventDefault();

        // Call our handler...
        props.onSelectFolder(event.target.href);

        // Redirect
        history.push(path);
    };

    // Our folder links...
    const nonclickEntry = t => <Typography key={t}>{t}</Typography>,
        clickEntry = fi => (
            <Link
                color='inherit'
                key={fi.id}
                href={fi.id}
                onClick={handleFolderSelect.bind(null, fi.path)}>
                {fi.name}
            </Link>
        ),
        linkHtml = props.folders
            .map(fi => (props.edit ? nonclickEntry(fi.name) : clickEntry(fi)))
            .concat(
                nonclickEntry(
                    props.selectedSong && props.selectedSong.songTitle
                )
            );

    // Our (moment) date format string
    const dateFormat = 'MMM Do YYYY [at] h:mma';

    // Construct our checkboxes...
    const fclArraySongSections = sectionState.songSections.map(ss => (
        <FormControlLabel
            key={ss.field}
            control={
                <Switch
                    size='small'
                    checked={ss.show}
                    onChange={handleSongSectionChange}
                    name={ss.field}
                />
            }
            label={ss.title}
        />
    ));

    const fclArrayChartSections = sectionState.chartSections
        .filter(cs => cs.allowShowHide)
        .map(cs => (
            <FormControlLabel
                key={cs.field}
                control={
                    <Switch
                        size='small'
                        checked={cs.show}
                        onChange={handleChartSectionChange}
                        name={cs.field}
                    />
                }
                label={cs.title}
            />
        ));

    let songInfoComponent, chartSectionComponent;
    if (props.selectedSong) {
        songInfoComponent = props.edit ? (
            <EditableSongInfo
                artistName={props.selectedSong.artistName}
                artistName_err={props.selectedSong.artistName_err}
                barsLeadIn={props.selectedSong?.[F_BARS_LEAD_IN]}
                beatsPerMeasure={props.selectedSong.beatsPerMeasure}
                beatsPerMeasure_err={props.selectedSong.beatsPerMeasure_err}
                disableSongSave={props.disableSongSave}
                eachBeatIsANote={props.selectedSong.eachBeatIsANote}
                saveSongInfo={props.saveSongInfo}
                songKey={props.selectedSong?.songKey}
                songTitle={props.selectedSong.songTitle}
                songTitle_err={props.selectedSong.songTitle_err}
                summary={props.selectedSong.summary}
                tempo={props.selectedSong.tempo}
                tempo_err={props.selectedSong.tempo_err}
            />
        ) : (
            <ReadOnlySongInfo
                artistName={props.selectedSong.artistName}
                barsLeadIn={props.selectedSong?.[F_BARS_LEAD_IN]}
                beatsPerMeasure={props.selectedSong.beatsPerMeasure}
                chartInfo={props.selectedSong.chartInfo}
                creationDate={props.selectedSong.creationDate}
                creationUser={props.selectedSong.creationUser}
                dateFormat={dateFormat}
                eachBeatIsANote={props.selectedSong.eachBeatIsANote}
                lastModificationDate={props.selectedSong.lastModificationDate}
                lastModificationUser={props.selectedSong.lastModificationUser}
                showDates={
                    sectionState.songSections.filter(
                        ss => ss.field === 'dates'
                    )[0].show
                }
                showRecInfo={
                    sectionState.songSections.filter(
                        ss => ss.field === 'recinfo'
                    )[0].show
                }
                showTimeInfo={
                    sectionState.songSections.filter(
                        ss => ss.field === 'timeinfo'
                    )[0].show
                }
                songKey={props.selectedSong?.songKey}
                songTitle={props.selectedSong.songTitle}
                summary={props.selectedSong.summary}
                tempo={props.selectedSong.tempo}
                version={props.selectedSong.version}
            />
        );

        chartSectionComponent = props.edit ? (
            <EditableChartSection
                barsLeadIn={props.selectedSong?.[F_BARS_LEAD_IN]}
                chartInfoArray={props.chartInfoArray}
                chartSections={sectionState.chartSections.filter(cs => cs.show)}
                onChangeSongSection={props.onChangeSongSection}
            />
        ) : (
            <ReadOnlyChartSection
                barsLeadIn={props.selectedSong?.[F_BARS_LEAD_IN]}
                chartInfoArray={props.chartInfoArray}
                chartSections={sectionState.chartSections.filter(cs => cs.show)}
            />
        );
    }

    // If we don't have a selected song, just get out now...
    if (!props.selectedSong) {
        return null;
    }

    function onDownload() {
        // Construct our list of fields to show
        const fieldsToShow = ['songSections', 'chartSections'].reduce(
            (arr, strSection) => {
                // Construct an array of fields to show for this section
                const sectionFieldsToShow = sectionState[strSection]
                    .filter(fi => fi.show)
                    .map(fi => fi.field);

                return arr.concat(...sectionFieldsToShow);
            },
            []
        );

        // Invoke our supplied function
        props.onSongDownload(
            persistedSongId,
            props.selectedSong.songTitle,
            fieldsToShow
        );
    }

    return (
        <div className={classes.root}>
            <div className='breadcrumbs-and-control-bar'>
                <div className='breadcrumbs'>
                    <Typography display='inline' className='prompt'>
                        <em>Path:&nbsp;</em>
                    </Typography>
                    <Breadcrumbs aria-label='breadcrumb'>
                        {linkHtml}
                    </Breadcrumbs>
                </div>
                <div className='control-bar'>
                    <IconButton aria-label='print' onClick={window.print}>
                        <PrintIcon />
                    </IconButton>
                    <IconButton aria-label='download' onClick={onDownload}>
                        <GetAppIcon />
                    </IconButton>
                </div>
            </div>
            <div className='options'>
                <FormControl
                    component='fieldset'
                    className={classes.formControl}
                    size='small'>
                    <FormLabel component='legend'>
                        Which sections to show?
                    </FormLabel>
                    {!props.edit && (
                        <FormGroup row={true}>{fclArraySongSections}</FormGroup>
                    )}
                    <FormGroup row={true}>{fclArrayChartSections}</FormGroup>
                </FormControl>
            </div>
            <div className={clsx('Song', props.edit && 'editable', 'App')}>
                <div className='song-header'>{songInfoComponent}</div>
                {chartSectionComponent}
                <Box className='print-footer'>
                    Generated by My Band Charts - https://mybandcharts.com - A
                    free site for organizing your band's charts
                </Box>
            </div>
        </div>
    );
}

Song.propTypes = {
    chartInfoArray: PropTypes.array.isRequired,
    disableSongSave: PropTypes.func.isRequired,
    edit: PropTypes.bool.isRequired,
    folders: PropTypes.array.isRequired,
    onChangeSongSection: PropTypes.func.isRequired,
    onSelectFolder: PropTypes.func.isRequired,
    onSelectSong: PropTypes.func.isRequired,
    saveSongInfo: PropTypes.func.isRequired,
    selectedSong: PropTypes.shape({
        artistName: PropTypes.string,
        artistName_err: PropTypes.string,
        beatsPerMeasure: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]).isRequired,
        beatsPerMeasure_err: PropTypes.string,
        chartInfo: PropTypes.array,
        creationDate: PropTypes.number,
        creationUser: PropTypes.string,
        eachBeatIsANote: PropTypes.number,
        lastModificationDate: PropTypes.number,
        lastModificationUser: PropTypes.string,
        songTitle: PropTypes.string,
        songTitle_err: PropTypes.string,
        summary: PropTypes.string,
        tempo: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
            .isRequired,
        tempo_err: PropTypes.string,
        version: PropTypes.number,
    }),
};

export default Song;
