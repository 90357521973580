// External modules: React
import React from 'react';
import PropTypes from 'prop-types';

// External Module: React OAuth
import { GoogleLogin } from '@react-oauth/google';

// Internal modules...
import {backendLogin, backendLogout} from './common.js';

// Handler fns
let onIsLoggedOn, onLogoutStarted, onLogoutSuccess, onLogoutFailed;

// No op
// The only reason that this still exists is that FixedAuth provides one (though it does not fully work)
export const handleLogin = () => {};

export const handleLogout = () => {
    // Logout of the backend...
    backendLogout({userInitiated: true, onIsLoggedOn, onLogoutStarted, onLogoutSuccess, onLogoutFailed});
};

function GoogleAuth(props) {
    // Remember some fns that can be invoked by handleLogout
    onIsLoggedOn = props.onIsLoggedOn;
    onLogoutStarted = props.onLogoutStarted;
    onLogoutSuccess = props.onLogoutSuccess;
    onLogoutFailed = props.onLogoutFailed;

    return (
        <GoogleLogin
            onSuccess={credentialResponse => {
                // Get fields for the backendLogin call
                const idToken = credentialResponse.credential;
                const userInitiated = true;
                const onLoginStarted = props.onLoginStarted;
                const onLoginSuccess = props.onLoginSuccess;
                const onLoginFailed = props.onLoginFailed;

                // Log into the backend...
                backendLogin({userInitiated, idToken, onIsLoggedOn, onLoginStarted, onLoginSuccess, onLoginFailed});
            }}
            onError={() => {
                // Indicate that our login failed...
                props.onLoginFailed();
            }}
            type='standard'
        />
    );
}

GoogleAuth.propTypes = {
    onIsLoggedOn: PropTypes.func.isRequired,
    onLoginFailed: PropTypes.func.isRequired,
    onLoginStarted: PropTypes.func.isRequired,
    onLoginSuccess: PropTypes.func.isRequired,
    onLogoutFailed: PropTypes.func.isRequired,
    onLogoutStarted: PropTypes.func.isRequired,
    onLogoutSuccess: PropTypes.func.isRequired,
};

export default GoogleAuth;
