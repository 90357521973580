// External modules
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Typography, Paper, IconButton, Link } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {Link as RouterLink} from 'react-router-dom';

// Construct our styles...
const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        minHeight: '100vh',
    },
    title: {
        float: 'left',
    },
    controlBar: {
        float: 'right',
    },
    body: {
        clear: 'both',
        '& UL': {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            marginLeft: theme.spacing(4),
        },
    },
    faqQuestion: {
        fontStyle: 'italic',
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(2),
        fontWeight: '400',
        '&:nth-of-type(1)': {
            marginTop: theme.spacing(2),
        }
    },
    faqAnswer: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(0),
    },
    body1: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(0),
    }
}));

function HelpPage(props) {
    // Use our compiled CSS classes
    const classes = useStyles();

    return (
        <Paper className={classes.root}>
            <Typography variant="h5" className={classes.title}>
                My Band Charts Help
            </Typography>
            <div className={classes.controlBar}>
                <IconButton aria-label="close" component={RouterLink} to="/">
                    <CloseIcon />
                </IconButton>
            </div>
            <Typography variant="body1" className={classes.body} component="div">
                If you are looking for help, you have several options:
                <ul>
                    <li><Link href="#faq">Review the Frequently Asked Questions (FAQ)</Link></li>
                    <li><Link href="#email">Send us an email</Link></li>
                </ul>
            </Typography>

            <Typography id="faq" variant="h6">FAQ</Typography>

            <Typography className={classes.faqQuestion}>
                Q1: What does this site do?
            </Typography>
            <Typography className={classes.faqAnswer}>
                A1: This website makes it easier for a band or (member of a band) to <strong>write down</strong> the structure of
                a song and present it in a <strong>concise</strong> format. That is, identify the <strong>parts</strong> of the song
                (INTRO, VERSE, CHORUS, etc) and their <strong>duration</strong> (in number of bars).
            </Typography>
            <Typography className={classes.faqAnswer}>
                In addition to the gross outline of the song,
                you can include notes for a section (e.g., "GUITAR STARTS ALONE", "FULL BAND JOINS" and "BAND STOPS ON 1 OF 8TH BAR")
                as well as instructions for each instrument (i.e., what the DRUMS are doing, GUITAR chord progression, etc).
            </Typography>
            <Typography className={classes.faqAnswer}>
                You can also write down the time signature (in terms of the number of beats in a measure), tempo (in beats per minute)
                and an overall summary of the song.
            </Typography>

            <Typography className={classes.faqQuestion}>
                Q2: Is this site really necessary?
            </Typography>
            <Typography className={classes.faqAnswer}>
                A2: Clearly, it is not. Bands have always found ways to communicate the structure of their songs. Often it is done verbally and
                members commit it to memory. Sometimes, the song structure is on a white board. Sometimes, members write them down in their own
                shorthand notation.
            </Typography>
            <Typography className={classes.faqAnswer}>
                The problem with all of these approaches is that there is no <em>definitive source</em>. If a change is made to a song, each member
                must memorize the change. Sometimes these changes "stack up" and members forget their part. This software aims to make it easy
                to write down the structure and keep it "alive".
            </Typography>

            <Typography className={classes.faqQuestion}>
                Q3: Does this site produce music notation (e.g., staffs, notes, bars, in a graphical way)?
            </Typography>
            <Typography className={classes.faqAnswer}>
                A3: No, it does not. The music notation varies between different instruments. Drummers use a staff (similar to other melodic instruments),
                but each line or space means something different. Further, this site assumes that band members are familiar with their songs and parts.
                Instead of trying to record each note, <strong>this site focuses on the overall song structure</strong> and ensuring that all members are
                "on the same page".
            </Typography>

            <Typography className={classes.faqQuestion}>
                Q4: Does this site provide a global directory of songs?
            </Typography>
            <Typography className={classes.faqAnswer}>
                A4: No, it does not. Unlike other sites where the focus is on learning <strong>published songs</strong> from professional artists, this
                site is for artists to manage their <em>own</em> songs. For this reason, the songs you write on this site are exclusively visible to you
                and those you designate. In this way, members of a band can collaborate on a song, each describing their own parts.
            </Typography>
            <Typography className={classes.faqAnswer}>
                Having said this, sometimes bands develop their own covers of published songs. They may modify the tempo, structure or feel of a published
                song to make it their own. In this way, they (and should) record this song structure as if it was any of their own originals. However, this
                cover is still only visible to you.
            </Typography>

            <Typography className={classes.faqQuestion}>
                Q5: If I use this site to define a song, can I control who can delete, edit or view it?
            </Typography>
            <Typography className={classes.faqAnswer}>
                A5: Yes. This site uses the terms, <strong>OWNER</strong>, <strong>EDITOR</strong> and <strong>READER</strong> to identify three classes of
                members. If you create a song, you are naturally the OWNER and have full control over the song. You, as an owner, have the exclusive right of
                deleting it. You can also specify other OWNERs by entering their email address in the SHARE page.
            </Typography>
            <Typography className={classes.faqAnswer}>
                If you want to grant a user the ability to EDIT a song, you can specify them as an <strong>EDITOR</strong>. Each EDITOR can change
                any part of the song, but not delete it.
            </Typography>
            <Typography className={classes.faqAnswer}>
                If you only want to a user the VIEW a song, you can specify them as a <strong>READER</strong>. Each READER can simply view or print
                the song.
            </Typography>

            <Typography className={classes.faqQuestion}>
                Q6: Who can share a song?
            </Typography>
            <Typography className={classes.faqAnswer}>
                A6: If you are the original OWNER of a song, you can decide who has access (including making someone else an OWNER). For anyone that has access,
                they can share it with others, <strong>only granting those users the same access they have</strong>.
            </Typography>
            <Typography className={classes.faqAnswer}>
                For example, if I create a song, and grant <strong>Greg</strong> the ability to EDIT it, then Greg can share it with anyone else, but only give
                those users the ability to EDIT or READ it. Greg <strong>cannot</strong> share it with <strong>Dan</strong> in a way that allows Dan to DELETE it.
            </Typography>

            <Typography className={classes.faqQuestion}>
                Q7: If I create a song on your site, do I forfeit any rights to it?
            </Typography>
            <Typography className={classes.faqAnswer}>
                A7: No! You have full control over this content. It will not be shared <strong>in any way</strong> with anyone that you do not designate. At any
                time you can remove your content and prevent others from seeing it.
            </Typography>

            <Typography className={classes.faqQuestion}>
                Q8: What's the catch? How are you monetizing this?
            </Typography>
            <Typography className={classes.faqAnswer}>
                A8: This is a free site for bands. It will always be a free site. I am both a software engineer and band member. I had to learn several
                songs (as a fill-in drummer) and wanted a more structured way to make my notes. I wrote this software to make my life easier. If I can make
                another band member's life easier, than I am satisfied.
            </Typography>
            <Typography className={classes.faqAnswer}>
                Having said that, I cannot guarantee that this site will be available forever. I am paying the hosting fees and will continue to do so as
                long as this site has users and provides value to them.
            </Typography>

            <Typography id="email" variant="h6">Email</Typography>

            <Typography variant="body1"className={classes.body1}>
                You can always reach us at
                &nbsp;<Link href="mailto:info@mybandcharts.com" target="_blank" rel="noreferrer">info@mybandcharts.com</Link>.&nbsp;
                Someone will get back to you right away.
            </Typography>
        </Paper>
    );
}

export default HelpPage;