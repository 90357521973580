// External module: React
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// External Module: Material UI
import {makeStyles} from '@material-ui/core/styles';
import {AppBar} from '@material-ui/core';

// External Module: React Router DOM
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";

// External Module: React OAuth
import {GoogleOAuthProvider} from '@react-oauth/google';

// Get our CSS
import './App.scss';

// Our local modules
import Song from './Song';
import NavBar from './NavBar';
import SongList from './SongList';
import HelpPage from './HelpPage';
import LandingPage from './LandingPage';
import LoginPage from './LoginPage';
import ProtectedRoute from './ProtectedRoute';
import {hasMoreAccess} from './accessType';

// Our client ID (this is at least it for our "DEV" environment, w/o a custom domain name)
// TODO This should be outside of the code base, perhaps in the package.json? Based on an env var?
const CLIENT_ID = '617423945776-hmsufmdkbhpppcfj0c1ubc4c9014h0k2.apps.googleusercontent.com';

// Construct our styles...
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
}));

/**
 * The overall Application
 */
function App(props) {
  // Use our compiled CSS classes
  const classes = useStyles();

  // Sort the Charts by song title (case insensitive)
  const {arrCharts, onAuthInitStarted} = props;

  // A list of all folders...
  const folders = [{id: 1, name: 'Home', path: "/home"}];

  // Conditions to show the loading spinner
  const showLoading = props.authInitializing === true || props.loggingIn === true || props.requestingCharts === true || !!props.getSessionStarted,
      loadingMsg = props.authInitializing === true ? 'Initializing Authentication...' :
      (props.getSessionStarted === true ? 'Checking for existing session...' :
          (props.loggingIn === true ? 'Logging you in...' :
          (props.requestingCharts === true ? 'Retrieving your charts...' : '')));

  // Do only once...
  useEffect(() => {
    // Indicate that the initialization of the authentication system has started
    onAuthInitStarted();
  }, [onAuthInitStarted]);

  return (
    <GoogleOAuthProvider
        clientId={CLIENT_ID}
        onScriptLoadSuccess={props.onAuthInitSuccess}
        onScriptLoadError={props.onAuthInitFailed}
    >
      <Router>
        <div className="no-App">
          <div className={classes.root}>
            <AppBar position="static">
              <NavBar
                allowDelete={(props.selectedSong && props.selectedSong.acg === 'OWN') || false}
                allowEditing={(props.selectedSong && hasMoreAccess(props.selectedSong.acg, 'READ')) || false}
                basicProfile={props.basicProfile}
                cancelEditMode={props.cancelEditMode}
                editMode={props.editMode}
                getChartSharingStarted={props.getChartSharingStarted}
                handleLogout={props.handleLogout}
                isEditing={props.isEditing}
                isLoggedIn={props.isLoggedIn}
                isSaving={props.isSaving}
                isSavingDisabled={props.isSavingDisabled}
                loggingIn={props.loggingIn}
                onCreateChart={props.onCreateChart}
                onDeleteChart={props.onDeleteChart}
                onSdtClose={props.onNavBarSdtClose}
                onSelectFolder={props.onSelectFolder}
                onSharingAddUser={props.onSharingAddUser}
                onSharingDeleteUser={props.onSharingDeleteUser}
                onSharingDlgClose={props.onSharingDlgClose}
                onSharingReduceUser={props.onSharingReduceUser}
                onSharingReset={props.onSharingReset}
                onSharingStarted={props.onSharingStarted}
                requestingCharts={props.requestingCharts}
                resolveOutdatedVersion={props.resolveOutdatedVersion}
                saveSongErrCode={props.saveSongErrCode}
                saveSongStarted={props.saveSongStarted}
                sdtContentKey={props.navBarSdtContentKey}
                sdtInProgress={props.navBarSdtInProgress}
                sdtIsSuccess={props.navBarSdtIsSuccess}
                sdtMessage={props.navBarSdtMessage}
                sdtShow={props.navBarSdtShow}
                selectedSongId={props.selectedSongId}
                setEditMode={props.setEditMode}
                sharingAddUserMessage={props.sharingAddUserMessage}
                sharingBtnEnabled={props.sharingBtnEnabled}
                sharingDlgShow={props.sharingDlgShow}
                sharingSdtInProgress={props.sharingSdtInProgress}
                sharingSdtIsSuccess={props.sharingSdtIsSuccess}
                sharingSdtMessage={props.sharingSdtMessage}
                sharingSdtShow={props.sharingSdtShow}
                sharingSettings={props.sharingSettings}
                sharingSettingsDiff={props.sharingSettingsDiff}
                sharingSettingsOrig={props.sharingSettingsOrig}
                songTitle={props.selectedSong && props.selectedSong.songTitle}
                startupInProgress={showLoading}
              />
            </AppBar>
          </div>
          <Switch>
            <ProtectedRoute path="/home">
              <SongList
                basicProfile={props.basicProfile}
                getCharts={props.getCharts}
                onChangeSort={props.onChangeSongListSort}
                onSelectSong={props.selectSong}
                requestingCharts={props.requestingCharts}
                songInfos={arrCharts}
                sortField={props.songListSortField}
                sortOrder={props.songListSortOrder}
              />
            </ProtectedRoute>
            <ProtectedRoute path="/song/:friendlyName-:songId">
              <Song
                chartInfoArray={props.chartInfoArray}
                disableSongSave={props.disableSongSave}
                edit={props.isEditing}
                folders={folders}
                onChangeSongSection={props.onChangeSongSection}
                onSelectFolder={props.onSelectFolder}
                onSelectSong={props.selectSong}
                saveSongInfo={props.saveSongInfo}
                selectedSong={props.selectedSong}
                onSongDownload={props.onSongDownload}
              />
            </ProtectedRoute>
            <Route path="/login">
              <LoginPage
                authInitError={props.authInitError}
                authInitializing={props.authInitializing}
                checkSession={props.checkSession}
                getSessionStarted={props.getSessionStarted}
                handleLogin={props.handleLogin}
                isLoggedIn={props.isLoggedIn}
                loadingMsg={loadingMsg}
                loggingIn={props.loggingIn}
                loginError={props.loginError}
                onAuthInitFailed={props.onAuthInitFailed}
                onAuthInitStarted={props.onAuthInitStarted}
                onAuthInitSuccess={props.onAuthInitSuccess}
                onCheckIsLoggedOn={props.onCheckIsLoggedOn}
                onIsLoggedOn={props.onIsLoggedOn}
                onLoginFailed={props.onLoginFailed}
                onLoginStarted={props.onLoginStarted}
                onLoginSuccess={props.onLoginSuccess}
                onLogoutFailed={props.onLogoutFailed}
                onLogoutStarted={props.onLogoutStarted}
                onLogoutSuccess={props.onLogoutSuccess}
                showLoading={showLoading}
              />
            </Route>
            <Route path="/help">
              <HelpPage />
            </Route>
            <Route path="/">
              <LandingPage
                checkSession={props.checkSession}
                isLoggedIn={props.isLoggedIn}
              />
            </Route>
          </Switch>
        </div>
      </Router>
    </GoogleOAuthProvider>
  );
}

App.propTypes = {
  arrCharts: PropTypes.array,
  authInitError: PropTypes.bool,
  authInitializing: PropTypes.bool,
  basicProfile: PropTypes.shape({
    email: PropTypes.string.isRequired,
    fullName: PropTypes.string,
    givenName: PropTypes.string,
  }),
  cancelEditMode: PropTypes.func.isRequired,
  chartInfoArray: PropTypes.array.isRequired,
  checkSession: PropTypes.func.isRequired,
  disableSongSave: PropTypes.func.isRequired,
  editMode: PropTypes.string.isRequired,
  getChartSharingStarted: PropTypes.func.isRequired,
  getCharts: PropTypes.func.isRequired,
  getChartsError: PropTypes.string,
  getSessionStarted: PropTypes.bool,
  handleLogin: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  isSaving: PropTypes.bool,
  isSavingDisabled: PropTypes.bool,
  loggingIn: PropTypes.bool,
  loginError: PropTypes.string,
  navBarSdtContentKey: PropTypes.string,
  navBarSdtInProgress: PropTypes.bool,
  navBarSdtIsSuccess: PropTypes.bool,
  navBarSdtMessage: PropTypes.string,
  navBarSdtShow: PropTypes.bool.isRequired,
  onAuthInitFailed: PropTypes.func.isRequired,
  onAuthInitStarted: PropTypes.func.isRequired,
  onAuthInitSuccess: PropTypes.func.isRequired,
  onChangeSongListSort: PropTypes.func.isRequired,
  onChangeSongSection: PropTypes.func.isRequired,
  onCheckIsLoggedOn: PropTypes.func.isRequired,
  onCreateChart: PropTypes.func.isRequired,
  onDeleteChart: PropTypes.func.isRequired,
  onIsLoggedOn: PropTypes.func.isRequired,
  onLoginFailed: PropTypes.func.isRequired,
  onLoginStarted: PropTypes.func.isRequired,
  onLoginSuccess: PropTypes.func.isRequired,
  onLogoutFailed: PropTypes.func.isRequired,
  onLogoutStarted: PropTypes.func.isRequired,
  onLogoutSuccess: PropTypes.func.isRequired,
  onNavBarSdtClose: PropTypes.func.isRequired,
  onSelectFolder: PropTypes.func.isRequired,
  onSharingAddUser: PropTypes.func.isRequired,
  onSharingDeleteUser: PropTypes.func.isRequired,
  onSharingDlgClose: PropTypes.func.isRequired,
  onSharingReduceUser: PropTypes.func.isRequired,
  onSharingReset: PropTypes.func.isRequired,
  onSharingStarted: PropTypes.func.isRequired,
  requestingCharts: PropTypes.bool,
  resolveOutdatedVersion: PropTypes.func.isRequired,
  saveSongErrCode: PropTypes.number,
  saveSongInfo: PropTypes.func.isRequired,
  saveSongStarted: PropTypes.func.isRequired,
  selectSong: PropTypes.func.isRequired,
  selectedSong: PropTypes.shape({
    acg: PropTypes.string,
    artistName: PropTypes.string,
    artistName_err: PropTypes.string,
    beatsPerMeasure: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    beatsPerMeasure_err: PropTypes.string,
    chartInfo: PropTypes.array,
    creationDate: PropTypes.number,
    creationUser: PropTypes.string,
    eachBeatIsANote: PropTypes.number,
    lastModificationDate: PropTypes.number,
    lastModificationUser: PropTypes.string,
    songTitle: PropTypes.string,
    songTitle_err: PropTypes.string,
    summary: PropTypes.string,
    tempo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    tempo_err: PropTypes.string,
    version: PropTypes.number,
  }),
  selectedSongId: PropTypes.string,
  setEditMode: PropTypes.func.isRequired,
  sharingAddUserMessage: PropTypes.string,
  sharingBtnEnabled: PropTypes.bool.isRequired,
  sharingDlgShow: PropTypes.bool.isRequired,
  sharingSdtInProgress: PropTypes.bool,
  sharingSdtIsSuccess: PropTypes.bool,
  sharingSdtMessage: PropTypes.string,
  sharingSdtShow: PropTypes.bool,
  sharingSettings: PropTypes.array,
  sharingSettingsDiff: PropTypes.object,
  sharingSettingsOrig: PropTypes.array,
  songListSortField: PropTypes.string,
  songListSortOrder: PropTypes.string,
};

export default App;
