
// Internal Modules
import {
    CHECK_LOGIN_STATE_STARTED,
    LOGIN_STARTED, LOGIN_SUCCESS, LOGIN_STATE, LOGIN_FAILED,
    LOGOUT_STARTED, LOGOUT_FAILED, GET_SESSION_SUCCESS, GET_SESSION_STARTED, GET_SESSION_FAILED, LOGOUT_SUCCESS,
} from '../actions';

// Fields
export const FLD_BASIC_PROFILE = 'basicProfile';
export const FLD_BASPRO_EMAIL = 'email';
export const FLD_GET_SESSION_ERROR = 'getSessionError';
export const FLD_GET_SESSION_STARTED = 'getSessionStarted';
export const FLD_IS_LOGGED_IN = 'isLoggedIn';
export const FLD_LOGIN_ERROR = 'loginError';
export const FLD_LOGIN_STARTED = 'loggingIn';
export const FLD_LOGOUT_ERROR = 'logoutError';
export const FLD_LOGOUT_STARTED = 'loggingOut';
export function loginReducer(state = {}, action) {
    switch (action.type) {
        /* Login Actions */
        case GET_SESSION_STARTED:
            return Object.assign({}, state, {
                [FLD_GET_SESSION_STARTED]: true
            });
        case GET_SESSION_SUCCESS:
            return Object.assign({}, state, {
                [FLD_GET_SESSION_STARTED]: false,
                [FLD_GET_SESSION_ERROR]: null,
                [FLD_IS_LOGGED_IN]: true,
                [FLD_BASIC_PROFILE]: action.basicProfile,
            });
        case GET_SESSION_FAILED:
            return Object.assign({}, state, {
                [FLD_GET_SESSION_STARTED]: false,
                [FLD_GET_SESSION_ERROR]: action.error,
                [FLD_BASIC_PROFILE]: null,
                [FLD_IS_LOGGED_IN]: false,
                [FLD_LOGIN_STARTED]: false,
            });
        case CHECK_LOGIN_STATE_STARTED:  /* TODO We may want to use a different set of fields to convey this is happening */
            return Object.assign({}, state, {
                [FLD_LOGIN_STARTED]: true
            });
        case LOGIN_STATE:
            return Object.assign({}, state, {
                [FLD_LOGIN_STARTED]: false,
                [FLD_LOGIN_ERROR]: null,
                [FLD_IS_LOGGED_IN]: action.isLoggedIn,
                [FLD_BASIC_PROFILE]: action.basicProfile,
            });
        case LOGIN_STARTED:
            return Object.assign({}, state, {
                [FLD_LOGIN_STARTED]: true,
                [FLD_LOGIN_ERROR]: null,
                [FLD_BASIC_PROFILE]: null,
            });
        case LOGIN_SUCCESS:
            return Object.assign({}, state, {
                [FLD_LOGIN_STARTED]: false,
                [FLD_BASIC_PROFILE]: action.basicProfile,
                [FLD_IS_LOGGED_IN]: true,
            });
        case LOGIN_FAILED:
            return Object.assign({}, state, {
                [FLD_LOGIN_STARTED]: false,
                [FLD_LOGIN_ERROR]: action.error,
                [FLD_IS_LOGGED_IN]: false,
            });

        /* Logout Actions */
        case LOGOUT_STARTED:
            return Object.assign({}, state, {
                [FLD_LOGOUT_STARTED]: true,
                [FLD_LOGOUT_ERROR]: null,
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                [FLD_LOGIN_STARTED]: false,
                [FLD_LOGIN_ERROR]: null,
                [FLD_IS_LOGGED_IN]: false,
                [FLD_BASIC_PROFILE]: null,
            });
        case LOGOUT_FAILED:
            return Object.assign({}, state, {
                [FLD_LOGOUT_STARTED]: false,
                [FLD_LOGOUT_ERROR]: action.error
            });

        default:
            return state;
    }
}
