// External modules
import {connect} from 'react-redux';

// Local modules
import './index.css';
import App from './App';
import {selectChartInfoArray} from './reducers/getCharts';
import {handleLogin, handleLogout} from './auth/GoogleAuth';

import {
    authInitStarting, authInitSuccess, authInitFailed,
    checkIsLoggedOn, isLoggedOn,
    loginStarted, loginSuccessful, loginFailed,
    logoutStarted, logoutSuccessful, logoutFailed,
    selectSong,
    setEditMode, cancelEditMode, saveSongStarted, disableSongSave, changeSongSection,
    createChart, saveSongInfo,
    selectFolder, getChartStarted,
    closeNavBarSdt,
    getChartSharingStarted, closeSharingDlg,
    sharingAddUser, sharingDeleteUser, sharingReduceUser, sharingReset,
    patchChartSharingStarted,
    deleteChartStarted, resolveOutdatedVersion, changeSongListSort,
    getSessionStarted,
    getChartsStarted,
    songDownloadStarted,
} from './actions';

const mapStateToProps = state => {
    const chartInfoArray = selectChartInfoArray(state.getCharts);

    return {
        ...state.auth,
        ...state.login,
        ...state.getCharts,
        ...state.songMode,
        chartInfoArray,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onAuthInitStarted:      () => dispatch(authInitStarting()),
        onAuthInitSuccess:      () => dispatch(authInitSuccess()),
        onAuthInitFailed:       error => dispatch(authInitFailed(error)),

        onCheckIsLoggedOn:      () => dispatch(checkIsLoggedOn()),
        onIsLoggedOn:           (ilo, basicProfile) => dispatch(isLoggedOn(ilo, basicProfile)),

        onLoginStarted:         () => dispatch(loginStarted()),
        onLoginSuccess:         basicProfile => dispatch(loginSuccessful(basicProfile)),
        onLoginFailed:          error => dispatch(loginFailed(error)),

        onLogoutStarted:        () => dispatch(logoutStarted()),
        onLogoutSuccess:        () => dispatch(logoutSuccessful()),
        onLogoutFailed:         error => dispatch(logoutFailed(error)),

        checkSession:           () => dispatch(getSessionStarted()),
        getCharts:              () => dispatch(getChartsStarted()),
        handleLogin,
        handleLogout,

        selectSong:             (id, title, lastLoaded) => dispatch(selectSong(id, title, lastLoaded)),
        getChartStarted:        (id, title) => dispatch(getChartStarted(id, title)),
        resolveOutdatedVersion: (id, title) => dispatch(resolveOutdatedVersion(id, title)),
        onCreateChart:          () => dispatch(createChart()),
        setEditMode:            () => dispatch(setEditMode()),
        cancelEditMode:         () => dispatch(cancelEditMode()),
        saveSongStarted:        songTitle => dispatch(saveSongStarted(songTitle)),
        disableSongSave:        (section, disable) => dispatch(disableSongSave(section, disable)),
        onChangeSongSection:    (action, ix, songSection) => dispatch(changeSongSection(action, ix, songSection)),
        onSelectFolder:         folderId => dispatch(selectFolder(folderId)),
        saveSongInfo:           songInfo => dispatch(saveSongInfo(songInfo)),

        onNavBarSdtClose:       () => dispatch(closeNavBarSdt()),

        getChartSharingStarted: (chartId, songTitle) => dispatch(getChartSharingStarted(chartId, songTitle)),
        onSharingDlgClose:      () => dispatch(closeSharingDlg()),
        onSharingDeleteUser:    email => dispatch(sharingDeleteUser(email)),
        onSharingReduceUser:    email => dispatch(sharingReduceUser(email)),
        onSharingAddUser:       (email, accessType) => dispatch(sharingAddUser(email, accessType)),
        onSharingReset:         () => dispatch(sharingReset()),

        onSharingStarted:       (chartId, songTitle, hshSharingDiffs) => dispatch(patchChartSharingStarted(chartId, songTitle, hshSharingDiffs)),
        onDeleteChart:          (chartId, songTitle, history, toPath) => dispatch(deleteChartStarted(chartId, songTitle, history, toPath)),

        onChangeSongListSort:   (fieldName, sortOrder) => dispatch(changeSongListSort(fieldName, sortOrder)),

        onSongDownload:         (chartId, songTitle, fieldsToShow) => dispatch(songDownloadStarted(chartId, songTitle, fieldsToShow)),
    }
};

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);

export default AppContainer;