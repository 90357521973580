// External modules...
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import ShareIcon from '@material-ui/icons/Share';
import DeleteIcon from '@material-ui/icons/Delete';
import HelpIcon from '@material-ui/icons/Help';
import { Toolbar, Avatar, Button, Typography } from '@material-ui/core';
import { deepPurple } from '@material-ui/core/colors';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';

// Internal modules...
import './NavBar.scss';
import SlidedownTray from './SlidedownTray';
import SharingDialog from './SharingDialog';
import { EDIT_MODE_EDITING, EDIT_MODE_VIEWING, EDIT_MODE_SAVING, } from './editMode';
import { ERROR_UPDATING_OLDER_VERSION } from './apiErrorCode';

// Our local styles
const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
    cursor: 'pointer',
  },
  button: {
    margin: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  iconSmall: {
    fontSize: 20
  },
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginLeft: theme.spacing(2),
    color: theme.palette.getContrastText(deepPurple[500]), /* TODO How about palette.info? */
    backgroundColor: deepPurple[500],
  },
}));

function NavBar(props) {
  // Construct our classes
  const classes = useStyles();

  // Construct our Avatar HTML
  const avatarHtml = (() => {
    function getFullName() {
      if (props.basicProfile.fullName) {
        return props.basicProfile.fullName;
      }

      if (props.basicProfile.givenName && props.basicProfile.familyName) {
        return `${props.basicProfile.givenName} ${props.basicProfile.familyName}`;
      }

      if (props.basicProfile.givenName) {
        return props.basicProfile.givenName;
      }

      return '';
    }

    // If we are not logged in, or lack a basic profile, then get out now...
    if (!props.isLoggedIn || !props.basicProfile) {
      return null;
    }

    // Do we have an image URL?
    const {fullName, givenName, familyName, imageUrl} = props.basicProfile;
    if (imageUrl) {
      return <Avatar alt={getFullName()} src={imageUrl} className={classes.avatar} />
    }

    // Do we have a given and family name?
    if (givenName && familyName) {
      // Construct our initials by uppercasing the first characters of both strings...
      const initials = (givenName.charAt(0) + familyName.charAt(0)).toUpperCase();

      return <Avatar alt={getFullName()} className={classes.avatar}>{initials}</Avatar>;
    }

    // Get up to the first two characters of the full name...
    if (fullName) {
      const initials = fullName.split(/\s+/, 2).map(s => s.charAt(0).toUpperCase()).join('');

      return <Avatar alt={getFullName()} className={classes.avatar}>{initials}</Avatar>;
    }

    return null;
  })();

  // Our handler for when the sharing starts...
  const onSharingClick = props.getChartSharingStarted.bind(null, props.selectedSongId, props.songTitle);

  // Whether we encountered an error during SAVE due to version out of date
  const outdatedVer = props.saveSongErrCode === ERROR_UPDATING_OLDER_VERSION,
    getLatestText = 'Get Latest';

  // Get our React Router History object...
  const history = useHistory();

  // Get our React Router Location object...
  const location = useLocation();

  // Are we in a song route?
  const inSongRoute = location && location.pathname.startsWith('/song/');

  // What to do when the user clicks "Create" chart
  const onCreateClick = () => {
    // Invoke our Redux Action
    props.onCreateChart();

    // Change our route
    history.push("/song/New_Chart-123456");
  };

  // What to do when the user clicks "Delete" chart
  const onDeleteClick = () => {
    // Start the deletion of the chart
    props.onDeleteChart(props.selectedSongId, props.songTitle, history, "/home");
  };

  return (
    <>
      <Toolbar>
        <Typography variant="h6" className={classes.title} onClick={() => props.isLoggedIn ? history.push("/home") : () => {}}>
          My Band Charts
        </Typography>
        { props.isLoggedIn && props.requestingCharts === false && props.isEditing === false &&
          <Button variant="contained" size="small" className={classes.button} onClick={onCreateClick} disabled={props.sdtInProgress}>
            Create
            <AddIcon className={clsx(classes.rightIcon, classes.iconSmall)} />
          </Button>
        }
        { props.selectedSongId && props.editMode === EDIT_MODE_VIEWING && props.allowEditing && inSongRoute &&
          <Button variant="contained" size="small" className={classes.button} onClick={props.setEditMode} disabled={props.sdtInProgress}>
            Edit
            <EditIcon className={clsx(classes.rightIcon, classes.iconSmall)} />
          </Button>
        }
        { props.selectedSongId && props.editMode === EDIT_MODE_VIEWING && props.allowDelete && inSongRoute &&
          <Button variant="contained" size="small" className={classes.button} onClick={onDeleteClick} disabled={props.sdtInProgress}>
            Delete
            <DeleteIcon className={clsx(classes.rightIcon, classes.iconSmall)} />
         </Button>
        }
        { props.editMode === EDIT_MODE_EDITING && inSongRoute &&
          <>
            <Button disabled={props.isSavingDisabled} variant="contained" color="primary" size="small" className={classes.button} onClick={() => {props.saveSongStarted(props.songTitle)}}>
              Save
              <SaveIcon className={clsx(classes.rightIcon, classes.iconSmall)} />
            </Button>
            <Button variant="contained" size="small" className={classes.button} onClick={props.cancelEditMode}>
              cancel
            </Button>
          </>
        }
        { props.isLoggedIn && props.requestingCharts === false && props.selectedSongId && inSongRoute &&
          <Button variant="contained" size="small" className={classes.button} onClick={onSharingClick} disabled={!props.sharingBtnEnabled || props.sdtInProgress}>
          Share
          <ShareIcon className={clsx(classes.rightIcon, classes.iconSmall)} />
        </Button>
        }
        { props.editMode === EDIT_MODE_SAVING && inSongRoute &&
          <Button variant="outlined" size="small" disabled={true} className={classes.button}>
            {props.editMode}...
          </Button>
        }
        { !props.startupInProgress &&
          <Button variant="contained" size="small" className={classes.button} component={RouterLink} to="/help">
            Help
            <HelpIcon className={clsx(classes.rightIcon, classes.iconSmall)} />
          </Button>
        }
        { props.isLoggedIn &&
          <Button color="inherit" onClick={props.handleLogout} disabled={props.sdtInProgress}>Logout</Button>
        }
        { props.isLoggedIn === false && props.loggingIn === false &&
          <Button color="inherit" component={RouterLink} to="/login">Login</Button>
        }
        { avatarHtml }
      </Toolbar>
      <SlidedownTray
        addCloseIcon={true}
        addTextButton={outdatedVer}
        autoCloseOnSuccessIn={4}
        contentKey={props.sdtContentKey}
        inProgress={props.sdtInProgress}
        isSuccess={props.sdtIsSuccess}
        message={outdatedVer ? `${props.sdtMessage} Click '${getLatestText.toUpperCase()}' to load latest.` : props.sdtMessage}
        onClose={props.onSdtClose}
        onTextButtonClick={outdatedVer ? props.resolveOutdatedVersion.bind(null, props.selectedSongId, props.songTitle) : null}
        show={props.sdtShow}
        textButton={outdatedVer ? getLatestText : ''}
      />
      <SharingDialog
        addUserMessage={props.sharingAddUserMessage}
        chartId={props.selectedSongId}
        onAddUser={props.onSharingAddUser}
        onClose={props.onSharingDlgClose}
        onDeleteUser={props.onSharingDeleteUser}
        onReduceUser={props.onSharingReduceUser}
        onReset={props.onSharingReset}
        onSave={props.onSharingStarted}
        sdtInProgress={props.sharingSdtInProgress}
        sdtIsSuccess={props.sharingSdtIsSuccess}
        sdtMessage={props.sharingSdtMessage}
        sdtShow={props.sharingSdtShow}
        sharingSettings={props.sharingSettings}
        sharingSettingsDiff={props.sharingSettingsDiff}
        sharingSettingsOrig={props.sharingSettingsOrig}
        show={props.sharingDlgShow}
        songTitle={props.songTitle}
        userId={props.basicProfile && props.basicProfile.email}
      />
    </>
  )
}

NavBar.propTypes = {
  allowDelete: PropTypes.bool.isRequired,
  allowEditing: PropTypes.bool.isRequired,
  basicProfile: PropTypes.shape({
    email: PropTypes.string,
    familyName: PropTypes.string,
    fullName: PropTypes.string,
    givenName: PropTypes.string,
  }),
  cancelEditMode: PropTypes.func.isRequired,
  editMode: PropTypes.string.isRequired,
  getChartSharingStarted: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  isSavingDisabled: PropTypes.bool.isRequired,
  loggingIn: PropTypes.bool,
  onCreateChart: PropTypes.func.isRequired,
  onDeleteChart: PropTypes.func.isRequired,
  onSdtClose: PropTypes.func.isRequired,
  onSelectFolder: PropTypes.func.isRequired,
  onSharingAddUser: PropTypes.func.isRequired,
  onSharingDeleteUser: PropTypes.func.isRequired,
  onSharingDlgClose: PropTypes.func.isRequired,
  onSharingReduceUser: PropTypes.func.isRequired,
  onSharingReset: PropTypes.func.isRequired,
  onSharingStarted: PropTypes.func.isRequired,
  requestingCharts: PropTypes.bool,
  resolveOutdatedVersion: PropTypes.func.isRequired,
  saveSongErrCode: PropTypes.number,
  saveSongStarted: PropTypes.func.isRequired,
  sdtContentKey: PropTypes.string,
  sdtInProgress: PropTypes.bool,
  sdtIsSuccess: PropTypes.bool,
  sdtMessage: PropTypes.string,
  sdtShow: PropTypes.bool.isRequired,
  selectedSongId: PropTypes.string,
  setEditMode: PropTypes.func.isRequired,
  sharingAddUserMessage: PropTypes.string,
  sharingBtnEnabled: PropTypes.bool.isRequired,
  sharingDlgShow: PropTypes.bool.isRequired,
  sharingSdtInProgress: PropTypes.bool,
  sharingSdtIsSuccess: PropTypes.bool,
  sharingSdtMessage: PropTypes.string,
  sharingSdtShow: PropTypes.bool,
  sharingSettings: PropTypes.array,
  sharingSettingsDiff: PropTypes.object,
  sharingSettingsOrig: PropTypes.array,
  songTitle: PropTypes.string,
  startupInProgress: PropTypes.bool,
};

export default NavBar;
