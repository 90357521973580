import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {Typography, CircularProgress} from '@material-ui/core';

// Construct our styles...
const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1),
    },
    progress: {
        margin: theme.spacing(1)
    },
    loading: {
        bottom: theme.spacing(1),
        position: 'relative'
    },
}));

export default function LoadingMessage(props) {
    // Use our compiled CSS classes
    const classes = useStyles();

    return(
        <div className={classes.root}>
            <CircularProgress className={classes.progress} size={12} />
            <Typography variant="caption" className={classes.loading}>{props.text}</Typography>
        </div>
    );
}

LoadingMessage.propTypes = {
    text: PropTypes.string.isRequired,
}
