export const AUTH_INIT_FAILED = 'AUTH_INIT_FAILED';
export const AUTH_INIT_STARTED = 'AUTH_INIT_STARTED';
export const AUTH_INIT_SUCCESS = 'AUTH_INIT_SUCCESS';
export const CANCEL_EDIT = 'CANCEL_EDIT';
export const CHANGE_SONG_SECTION = 'CHANGE_SONG_SECTION';
export const CHECK_LOGIN_STATE_STARTED = 'CHECK_LOGIN_STATE_STARTED';
export const CLOSE_NAVBARSDT = 'CLOSE_NAVBARSDT';
export const CLOSE_SHARING_DLG = 'CLOSE_SHARING_DLG';
export const CREATE_CHART = 'CREATE_CHART';
export const DELETE_CHART_FAILED = 'DELETE_CHART_FAILED';
export const DELETE_CHART_STARTED = 'DELETE_CHART_STARTED';
export const DELETE_CHART_SUCCESS = 'DELETE_CHART_SUCCESS';
export const DISABLE_SONG_SAVE = 'DISABLE_SONG_SAVE';
export const GET_CHART_FAILED = 'GET_CHART_FAILED';
export const GET_CHART_SHARING_FAILED = 'GET_CHART_SHARING_FAILED';
export const GET_CHART_SHARING_STARTED = 'GET_CHART_SHARING_STARTED';
export const GET_CHART_SHARING_SUCCESS = 'GET_CHART_SHARING_SUCCESS';
export const GET_CHART_STARTED = 'GET_CHART_STARTED';
export const GET_CHART_SUCCESS = 'GET_CHART_SUCCESS';
export const GET_CHARTS_FAILED = 'GET_CHARTS_FAILED';
export const GET_CHARTS_STARTED = 'GET_CHARTS_STARTED';
export const GET_CHARTS_SUCCESS = 'GET_CHARTS_SUCCESS';
export const GET_SESSION_FAILED = 'GET_SESSION_FAILED';
export const GET_SESSION_STARTED = 'GET_SESSION_STARTED';
export const GET_SESSION_SUCCESS = 'GET_SESSION_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_STARTED = 'LOGIN_STARTED';
export const LOGIN_STATE = 'LOGIN_STATE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';
export const LOGOUT_STARTED = 'LOGOUT_STARTED';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const PATCH_CHART_SHARING_FAILED = 'PATCH_CHART_SHARING_FAILED';
export const PATCH_CHART_SHARING_STARTED = 'PATCH_CHART_SHARING_STARTED';
export const PATCH_CHART_SHARING_SUCCESS = 'PATCH_CHART_SHARING_SUCCESS';
export const RESOLVE_OUTDATED_VERSION = 'RESOLVE_OUTDATED_VERSION';
export const SAVE_SONG_FAILED = 'SAVE_SONG_FAILED';
export const SAVE_SONG_INFO = 'SAVE_SONG_INFO';
export const SAVE_SONG_STARTED = 'SAVE_SONG_STARTED';
export const SAVE_SONG_SUCCESS = 'SAVE_SONG_SUCCESS';
export const SELECT_FOLDER = 'SELECT_FOLDER';
export const SELECT_SONG = 'SELECT_SONG';
export const SET_EDIT_MODE = 'SET_EDIT_MODE';
export const SHARING_ADD_USER = 'SHARING_ADD_USER';
export const SHARING_DELETE_USER = 'SHARING_DELETE_USER';
export const SHARING_REDUCE_USER = 'SHARING_REDUCE_USER';
export const SHARING_RESET = 'SHARING_RESET';
export const SONG_LIST_CHANGE_SORT = 'SONG_LIST_CHANGE_SORT';
export const SONG_DOWNLOAD_STARTED = 'SONG_DOWNLOAD_STARTED';
export const SONG_DOWNLOAD_SUCCESS = 'SONG_DOWNLOAD_SUCCESS';
export const SONG_DOWNLOAD_FAILED = 'SONG_DOWNLOAD_FAILED';

// Authentication Initialization
export function authInitStarting() {
    return { type: AUTH_INIT_STARTED };
}
export function authInitSuccess() {
    return { type: AUTH_INIT_SUCCESS };
}

export function authInitFailed(error) {
    return { type: AUTH_INIT_FAILED, error };
}

// Session Checking
export function getSessionStarted() {
    return { type: GET_SESSION_STARTED };
}
export function getSessionSuccessful(basicProfile) {
    return { type: GET_SESSION_SUCCESS, basicProfile };
}
export function getSessionFailed(error) {
    return { type: GET_SESSION_FAILED, error };
}

// Whether the user is currently logged on or not
export function checkIsLoggedOn() {
    return { type: CHECK_LOGIN_STATE_STARTED };
}
export function isLoggedOn(loggedOn, basicProfile) {
    return { type: LOGIN_STATE, isLoggedIn: loggedOn, basicProfile };
}

// Login
export function loginStarted() {
    return { type: LOGIN_STARTED };
}
export function loginSuccessful(basicProfile) {
    return { type: LOGIN_SUCCESS, basicProfile };
}

export function loginFailed(error) {
    return { type: LOGIN_FAILED, error };
}

// Logout
export function logoutStarted() {
    return { type: LOGOUT_STARTED };
}
export function logoutSuccessful() {
    return { type: LOGOUT_SUCCESS };
}
export function logoutFailed(error) {
    return { type: LOGOUT_FAILED, error };
}

// Get GET_CHARTS_FAILED
export function getChartsStarted() {
    return { type: GET_CHARTS_STARTED };
}
export function getChartsSuccessful(charts) {
    return { type: GET_CHARTS_SUCCESS, charts };
}
export function getChartsFailed(error) {
    return { type: GET_CHARTS_FAILED, error };
}

export function getChartStarted(songId, songTitle) {
    return { type: GET_CHART_STARTED, songId, songTitle };
}

export function resolveOutdatedVersion(songId, songTitle) {
    return { type: RESOLVE_OUTDATED_VERSION, songId, songTitle };
}

export function getChartSuccessful(chart) {
    return { type: GET_CHART_SUCCESS, chart };
}

export function getChartFailed(error, songTitle) {
    return { type: GET_CHART_FAILED, error, songTitle };
}

const SONG_CACHE_TIME_IN_MINUTES = 60;
export function selectSong(id, title, lastLoaded) {
    // Is the song OLDER (lastLoaded) than SONG_CACHE_TIME minutes?
    if (
        lastLoaded &&
        lastLoaded + SONG_CACHE_TIME_IN_MINUTES * 60 * 1000 > Date.now()
    ) {
        // If so, proceed with simply selecting the cached song.
        return { type: SELECT_SONG, songId: id };
    }

    // Retrieve the latest version from the server...
    return getChartStarted(id, title);
}

export function createChart() {
    return { type: CREATE_CHART };
}

export function setEditMode() {
    return { type: SET_EDIT_MODE };
}

export function cancelEditMode() {
    return { type: CANCEL_EDIT };
}

export function saveSongStarted(songTitle) {
    return { type: SAVE_SONG_STARTED, songTitle };
}

export function saveSongSuccessful(songInfo) {
    return { type: SAVE_SONG_SUCCESS, songInfo };
}

export function saveSongFailed(error, code, songTitle) {
    return { type: SAVE_SONG_FAILED, error, code, songTitle };
}

export function disableSongSave(section, disable) {
    return { type: DISABLE_SONG_SAVE, section, disable };
}

export function changeSongSection(action, index, songSection) {
    return { type: CHANGE_SONG_SECTION, action, index, songSection };
}

export function selectFolder(folderId) {
    return { type: SELECT_FOLDER, folderId };
}

export function saveSongInfo(songInfo) {
    return { type: SAVE_SONG_INFO, songInfo };
}

export function closeNavBarSdt() {
    return { type: CLOSE_NAVBARSDT };
}

export function getChartSharingStarted(chartId, songTitle) {
    return { type: GET_CHART_SHARING_STARTED, chartId, songTitle };
}

export function getChartSharingSuccessful(chartId, songTitle, arrSharing) {
    return { type: GET_CHART_SHARING_SUCCESS, chartId, songTitle, arrSharing };
}

export function getChartSharingFailed(error, songTitle) {
    return { type: GET_CHART_SHARING_FAILED, error, songTitle };
}

export function closeSharingDlg() {
    return { type: CLOSE_SHARING_DLG };
}

export function sharingAddUser(email, accessType) {
    return { type: SHARING_ADD_USER, email, accessType };
}
export function sharingDeleteUser(email) {
    return { type: SHARING_DELETE_USER, email };
}

export function sharingReduceUser(email) {
    return { type: SHARING_REDUCE_USER, email };
}

export function sharingReset() {
    return { type: SHARING_RESET };
}

export function patchChartSharingStarted(chartId, songTitle, hshSharingDiffs) {
    return {
        type: PATCH_CHART_SHARING_STARTED,
        chartId,
        songTitle,
        hshSharingDiffs,
    };
}

export function patchChartSharingFailed(error, songTitle) {
    return { type: PATCH_CHART_SHARING_FAILED, error, songTitle };
}

export function patchChartSharingSuccessful(chartId, songTitle, acg) {
    return { type: PATCH_CHART_SHARING_SUCCESS, chartId, songTitle, acg };
}

export function deleteChartStarted(chartId, songTitle, history, toPath) {
    return { type: DELETE_CHART_STARTED, chartId, songTitle, history, toPath };
}

export function deleteChartSuccessful(chartId, songTitle) {
    return { type: DELETE_CHART_SUCCESS, chartId, songTitle };
}

export function deleteChartFailed(error, songTitle) {
    return { type: DELETE_CHART_FAILED, error, songTitle };
}

export function changeSongListSort(fieldName, sortOrder) {
    return { type: SONG_LIST_CHANGE_SORT, fieldName, sortOrder };
}

export function songDownloadStarted(chartId, songTitle, fieldsToShow) {
    return { type: SONG_DOWNLOAD_STARTED, chartId, songTitle, fieldsToShow };
}

export function songDownloadSuccessful(chartId, songTitle) {
    return { type: SONG_DOWNLOAD_SUCCESS, chartId, songTitle };
}

export function songDownloadFailed(error, songTitle) {
    return { type: SONG_DOWNLOAD_FAILED, error, songTitle };
}
