import {useEffect} from 'react';

export const replaceNewlineWithBreak = v => {
    if (!v || typeof v !== 'string') {
        return v;
    }

    return v.replace(/(?:\r\n|\r|\n)/g, '<br />');
};

export const getApiUrl = path => {
  return process.env.NODE_ENV === 'development' ?
    `http://localhost:3000${path}` :
    process.env.REACT_APP_REST_API_PREFIX + path;
};

const noOp = () => {};
export const useMount = fn => useEffect(() => {
    fn();
    return noOp;
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

export const constructSongPath = (songTitle, songId) => {
    const translateChar = ch => {
        if (/[a-zA-Z0-9]/.test(ch)) {
          return ch;
        } else if (/ /.test(ch)) {
          return '_'
        }
      },
      friendlySongName = Array.from(songTitle).map(translateChar).join('');

    return `/song/${friendlySongName}-${songId}`;
};