import React, {useReducer} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import {IconButton, Tooltip, TextareaAutosize} from '@material-ui/core';

// Internal modules
import {replaceNewlineWithBreak} from './util';
import { F_BARS_LEAD_IN } from './fields';

// Constants

// State fields...
const FLD_EDITING_SECTION_TUPLE = 'editingSectionTuple';
const FLD_SELECTED_SECTION = 'selectedSection';

// DOM Attribute
// TODO How to insert this into each table?
const DOM_ATTR_CHART_INDEX = 'chartindex';

// Actions
const INSERT_NEW_SECTION = 'INSERT_NEW_SECTION';
const SELECT_SECTION = 'SELECT_SECTION';
const CHANGE_SECTION_FIELD = 'CHANGE_SECTION_FIELD';
const CLEAR_SELECTION = 'CLEAR_SELECTION';

// Whether a string is an integer
const INT_REGEXP = new RegExp('^\\d+$');
const isInteger = s => INT_REGEXP.test(s);

// Our function component
function EditableChartSection(props) {
    // Show measure row?
    const blnShowMeasure = true;

    // The number of rows in our chart...
    const nRows = props.chartSections.length + (blnShowMeasure ? 1 : 0);

    // Toolbar button styles...
    const buttonToolbarStyles = (makeStyles(theme => ({
        button: {
            margin: theme.spacing(0.25)
        },
        icon: {
            fontSize: 16
        }
    })))();

    // Create a certain type of icon...
    const createIcon = ({tooltip, onClick, Component, color='default'}) => {
        return (
            <Tooltip title={tooltip}>
                <IconButton className={buttonToolbarStyles.button} color={color} size="small" onClick={onClick}>
                    <Component className={buttonToolbarStyles.icon} />
                </IconButton>
            </Tooltip>
        );
    };

    // Our reducer
    const reducer = (state, action) => {
        switch (action.type) {
            case INSERT_NEW_SECTION:
                return Object.assign({}, state, {});
            case SELECT_SECTION:
                return Object.assign({}, state, {
                    [FLD_EDITING_SECTION_TUPLE]: [action.chartIndex, action.colIndex],
                    [FLD_SELECTED_SECTION]: props.chartInfoArray[action.chartIndex][action.colIndex]
                });
            case CHANGE_SECTION_FIELD:
                return Object.assign({}, state, {
                    [FLD_SELECTED_SECTION]: Object.assign({}, state[FLD_SELECTED_SECTION], {[action.fieldName]: action.fieldValue})
                });
            case CLEAR_SELECTION:
                return Object.assign({}, state, {
                    [FLD_EDITING_SECTION_TUPLE]: null,
                    [FLD_SELECTED_SECTION]: null,
                });
            default:
                return state;
        }
    };

    // Our initial state...
    const initialState = {
        [FLD_EDITING_SECTION_TUPLE]: null,
    };

    // Our state...
    const [state, dispatch] = useReducer(reducer, initialState);

    // Action Creators...
    const selectSection = (chartIndex, colIndex) => dispatch({type: SELECT_SECTION, chartIndex, colIndex});
    const changeFieldOfSelectedSection = (fieldName, fieldValue) => dispatch({type: CHANGE_SECTION_FIELD, fieldName, fieldValue});
    const clearSelection = () => dispatch({type: CLEAR_SELECTION});

    // Parse the incoming table for a newly inserted sections...
    let selectedSection = false;
    props.chartInfoArray.forEach((ciArr, rowIndex) => {
        props.chartInfoArray[rowIndex].forEach((ci, colIndex) => {
            // Is this a newly inserted section?
            if (ci.insertNew && !selectedSection) {
                // Remove the marker...
                delete ci.insertNew;

                // Indicate that we are now selecting a section...
                selectedSection = true;

                // Select it...
                selectSection(rowIndex, colIndex);
            }
        });
    });
    // Convert a tuple address to an absolute index...
    const convertTupleToAbsIndex = (chartIndex, colIndex) => {
        return props.chartInfoArray.reduce((acc, ciArr, ix) => {
            // Have we not yet reached the correct chart array?
            if (ix < chartIndex) {
                // Increment the accumulator by the length of the current array
                acc += ciArr.length;
            } else if (chartIndex === ix) {
                acc += colIndex;
            }

            return acc;
        }, 0);
    };

    // Click handler for table...
    const onTableClick = evt => {
        // If we are actively editing a chart section...
        if (state[FLD_EDITING_SECTION_TUPLE] && state[FLD_SELECTED_SECTION]) {
            console.log(`Cannot select table cell: Already editing section: ${state[FLD_EDITING_SECTION_TUPLE]}`);
            return;
        }

        // Get our selected element... (Assumed to be HTMLTableCellElement?)
        const elm = evt.target;

        // If this is a "control" element, get out...
        if (elm.classList.contains("control")) {
            console.log(`Cannot select table cell: Control element`);
            return;
        }

        // Get our column index...
        const columnIx = elm && elm.closest("TD").cellIndex;

        // If we don't have a cellIndex, we may not be a TD...
        if (columnIx === undefined) {
            console.log(`Cannot select table cell: cellIndex is undefined`)
            return;
        }

        // Find the parent table...
        const tableElm = elm.closest("TABLE");

        // Get the table index...
        const tableIx = tableElm && parseInt(tableElm.getAttribute(DOM_ATTR_CHART_INDEX));

        // If we don't have a table index, get out now...
        if (tableIx === undefined) {
            console.log(`Cannot select table cell: Cannot determine tableIx`);
            return;
        }

        // Compute the associated column
        const assocColIx = columnIx - 1;

        // Trigger a state change...
        selectSection(tableIx, assocColIx);
    };

    // Is this section selected?
    const isTableColSelected = (tableIx, columnIx) => {
        if (!state[FLD_EDITING_SECTION_TUPLE] || state[FLD_EDITING_SECTION_TUPLE].length !== 2) {
          return false;
        }

        return state[FLD_EDITING_SECTION_TUPLE][0] === tableIx && state[FLD_EDITING_SECTION_TUPLE][1] === columnIx;
      };

    // Return whether the indices specify the LAST chart in the song...
    const lastSection = (tableIx, columnIx) => tableIx === props.chartInfoArray.length-1 && columnIx === props.chartInfoArray[tableIx].length-1;

    // Return some number of column elements...
    const getColumnElements = chartIndex => props.chartInfoArray[chartIndex].reduce((arr, ci, ix) => {
        // If this is the first index, add the column that holds our TH elements ("SONG FORM", "NOTES", "BARS", etc)
        if (ix === 0) {
            arr.push(<col key='hdrs' />);
        }

        // If we are NOT editing a table section...
        if (!state[FLD_EDITING_SECTION_TUPLE] || state[FLD_EDITING_SECTION_TUPLE].length === 0) {
            // Add a column to hold "insert section" and "break" (or "join")
            arr.push(<col key={`is:${ix}`} className="add-section" />);
        }

        // Add a column to hold the actual chart section
        arr.push(<col key={ix} className={clsx(isTableColSelected(chartIndex, ix) && "selected")} />);

        // Add a column to hold the trailing control section
        if (lastSection(chartIndex, ix)) {
            arr.push(<col key={`as:${ix}`} className="add-section" />);
        }

        return arr;
    }, []);

    // How to get the leading digits of a string
    const getDigits = str => {
        const digits = str.match(/\d/g);
        if (!digits) {
            return '';
        }
        return +(digits.join(''));
    };

    // What to do when an input element is changed...
    const onSectionElmChange = (fieldName, evt) => {
        // Our new value...
        const v = evt.target.value,
            newValue = (fieldName === 'bars') ? (isInteger(v) ? parseInt(v) : getDigits(v)) : v;

        // Set the selected song section...
        changeFieldOfSelectedSection(fieldName, newValue);
    };

    // Function Generator: function to add a section...
    const onAddSection = (tableIx, columnIx) => evt => {
        const absIx = convertTupleToAbsIndex(tableIx, columnIx);

        // Stop the propagation of the event up the DOM tree...
        evt.stopPropagation();

        // Call our handler to insert a new section
        props.onChangeSongSection('insertEmpty', absIx);
    };

    // Function Generator: function to split (insert a break) to a section
    const onBreakSection = (tableIx, columnIx) => evt => {
        const absIx = convertTupleToAbsIndex(tableIx, columnIx);

        // Stop the propagation of the event up the DOM tree...
        evt.stopPropagation();

        // Call our handler to insert a new section
        props.onChangeSongSection('break', absIx);
    }

    // Function Generator: function to join to the previous section
    const onJoinSection = (tableIx, columnIx) => evt => {
        const absIx = convertTupleToAbsIndex(tableIx, columnIx);

        // Stop the propagation of the event up the DOM tree...
        evt.stopPropagation();

        // Call our handler to insert a new section
        props.onChangeSongSection('join', absIx);
    }

    // Generate the HTML for each of the TDs in the CHART TABLE. This is an ARRAY of ARRAYS of TDs
    const tdArray = props.chartInfoArray.map((row, rowIndex) =>
        props.chartSections.map((cs, csIx) =>
            props.chartInfoArray[rowIndex].reduce((arr, ci, ix) => {
                const notEditingSection = !state[FLD_EDITING_SECTION_TUPLE] || state[FLD_EDITING_SECTION_TUPLE].length === 0,
                    onChange = onSectionElmChange.bind(onSectionElmChange, cs.field),
                    selSection = isTableColSelected(rowIndex, ix),
                    onAdd = onAddSection(rowIndex, ix),
                    addSectionIcon = createIcon({tooltip: 'insert', onClick: onAdd, Component: AddCircleOutlineIcon, color: 'primary'}),
                    onAppend = onAddSection(rowIndex, ix+1),
                    appendSectionIcon = createIcon({tooltip: 'append', onClick: onAppend, Component: AddCircleOutlineIcon, color: 'primary'});

                // If we do NOT have an editable section...
                if (notEditingSection && csIx === 0) {
                    const onBreak = onBreakSection(rowIndex, ix-1),
                        splitSectionIcon = createIcon({tooltip: 'split', onClick: onBreak, Component: CallSplitIcon, color: 'primary'}),
                        onJoin = onJoinSection(rowIndex, ix-1),
                        joinSectionIcon = createIcon({tooltip: 'join', onClick: onJoin, Component: MergeTypeIcon, color: 'primary'}),
                        showSplit = ix !== 0,
                        showJoin = ix === 0 && rowIndex !== 0;

                    arr.push(<td key={[rowIndex, ix, 'is'].join('::')} className="control" rowSpan={nRows}>
                            {addSectionIcon}
                            {showSplit && splitSectionIcon}
                            {showJoin && joinSectionIcon}
                        </td>);
                }

                // Add our actual song section...
                arr.push(<td key={[rowIndex, ix, cs.field].join('::')} className={'normal ' + cs.field}>
                    { selSection &&
                        <TextareaAutosize
                            minRows={['note', 'bass', 'drums', 'guitar'].includes(cs.field) ? 4 : 1}
                            maxRows={6}
                            className={cs.field}
                            onChange={onChange}
                            value={state[FLD_SELECTED_SECTION][cs.field]}
                        />
                    }
                    { !selSection &&
                        <div dangerouslySetInnerHTML={{__html: replaceNewlineWithBreak(ci[cs.field])}}></div>
                    }
                </td>);

                // Only add another control section, if we are at the END of the chart
                if (notEditingSection && lastSection(rowIndex, ix) && csIx === 0) {
                    arr.push(<td key={[rowIndex, ix, 'as'].join('::')} className="control" rowSpan={nRows}>
                            {appendSectionIcon}
                        </td>);
                }

                return arr;
            }, [])
        )
    );

    // What to do when someone approves of changes to the song section...
    const acceptChanges = () => {
        // TODO: ONLY ACCEPT IF ALL FORM FIELDS ARE VALID!
        // TBD

        // Get the index of the chart section
        const ix = convertTupleToAbsIndex(state[FLD_EDITING_SECTION_TUPLE][0], state[FLD_EDITING_SECTION_TUPLE][1]);

        // Tell our application that we want to change a song section...
        props.onChangeSongSection('replace', ix, state[FLD_SELECTED_SECTION]);

        // Clear the selected song section...
        // setSelectedSongSection({});

        // Also, indicate that we have not selected anything else...
        setTimeout(clearSelection, 10);
    };

    // What to do if we want to reject the selected changes...
    const rejectChanges = () => {
        // Clear the selected song section...
        // setSelectedSongSection({});

        // Also, indicate that we have not selected anything else...
        // dispatchSelected({type: 'CLEAR_COLUMN_TUPLE'});
        clearSelection();
    };

    // What to do if we want to delete the section...
    const removeSection = () => {
        // Get the index of the chart section
        const ix = convertTupleToAbsIndex(state[FLD_EDITING_SECTION_TUPLE][0], state[FLD_EDITING_SECTION_TUPLE][1]);

        // Tell our application that we want to change a song section...
        props.onChangeSongSection('delete', ix);

        // Clear the selected song section...
        // setSelectedSongSection({});

        // Also, indicate that we have not selected anything else...
        // dispatchSelected({type: 'CLEAR_COLUMN_TUPLE'});
        setTimeout(clearSelection, 10);
    };

    // Generate an ARRAY (1..chartInfoArray.length) of ARRAY (1..SECTIONS_IN_ROW.length) of ARRAY (1..2) with START_MEASURE, END_MEASURE
    const barNumArray = props.chartInfoArray.reduce((outerHash, rowArray, rowIndex) => {
        // Reduce the individual row into into a 2 DIMENSIONAL ARRAY
        const innerHash = props.chartInfoArray[rowIndex].reduce((res, obj) => {
            const nBars = obj.bars || 1;

            // Add to the array...
            res.arr.push([res.start, res.start+nBars-1]);

            // Increment the counter...
            res.start = res.start + nBars;

            return res;
        }, {start: outerHash.start, arr: []});

        // Append the inner hash's array to the outer one
        outerHash.arr.push(innerHash.arr);

        // Update the outer hash start index...
        outerHash.start = innerHash.start;

        return outerHash;
    }, {start: 1 + (props?.[F_BARS_LEAD_IN] || 0), arr: []}).arr;

  // Generate the HTML for each of the TDs in the MEASURE ROW of the CHART TABLE. This is an ARRAY of ARRAYS of TDs
    const tdBarNumHtml = props.chartInfoArray.map((row, rowIndex) =>
        props.chartInfoArray[rowIndex].map((ci, ix) =>
        <td key={[rowIndex, ix].join('::')} className='normal measure'>
            { (!isTableColSelected(rowIndex, ix)) && ((ci.bars > 1 &&
            <>
                <span className="bar-num-start">{barNumArray[rowIndex][ix][0]}</span>
                <span className="bar-num-end">{barNumArray[rowIndex][ix][1]}</span>
            </>
            ) || barNumArray[rowIndex][ix][0])}
            { isTableColSelected(rowIndex, ix) &&
            <div className="selected-cell-toolbar">
                {createIcon({tooltip: 'accept', onClick: acceptChanges, Component: CheckCircleIcon, color: 'primary'})}
                {createIcon({tooltip: 'cancel', onClick: rejectChanges, Component: HighlightOffIcon})}
                {createIcon({tooltip: 'remove', onClick: removeSection, Component: DeleteIcon, color: 'secondary'})}
            </div>
            }
        </td>
        )
    );

    // Generate the HTML for all CHART TABLEs. This is an ARRAY of TABLEs.
    const chartTableHtml = props.chartInfoArray.map((row, rowIndex) =>
        <table key={rowIndex} chartindex={rowIndex} onClick={onTableClick} className="chart-table">
            <colgroup>
                {getColumnElements(rowIndex)}
            </colgroup>
            <tbody>
                {props.chartSections.map((cs, ix) => <tr key={ix}><th>{cs.title}</th>{tdArray[rowIndex][ix]}</tr>)}
                <tr className="bar-nums"><th>MEASURE</th>{tdBarNumHtml[rowIndex]}</tr>
            </tbody>
        </table>
    );

    return chartTableHtml;
}

// Our PropTypes
EditableChartSection.propTypes = {
    chartInfoArray: PropTypes.array.isRequired,
    chartSections: PropTypes.array.isRequired,
    onChangeSongSection: PropTypes.func.isRequired,
}

export default EditableChartSection;
